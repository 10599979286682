import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchChatOfChatterOrGroupByMessageId, getChatterInfo, reactEmoji } from "../../../zustand/cloud/cloud.action";
import backgroundImageWhite from "../../../icons/whatsappBackground.png";
import backgroundImageBlack from "../../../icons/whatsappBackgroundBlack2.jpg"
import {
  convertChatObject,
  convertMessageObject,
  getLastMessageTime,
  updateReactions,
} from "../../../utils/helper";
import SentMessage from "../sentMessageForm/SentMessage";
import {
  addData,
  getChatsByToProperty,
  getData,
  openDB,
} from "../../../db/indexedDB";
import ClipLoader from "react-spinners/ClipLoader";
import TopBar from "../topBar/TopBar";
import OnlyAdminMessage from "../OnlyAdminMessage/OnlyAdminMessage";
import { getEazybeWebhookData, sendEazybeWebhookMessageData, sendWebhookSyncInfo } from "../../../zustand/webHook/webHook";
import { filterRecentChats, filterRecentMessages, getWorkspaceId } from "../../../util";
import { getWebhookLastSyncTime } from "../../../services /api/webhook";
import { toast } from "react-toastify";
import GroupIndividualUI from "../group-ui-indiviual-UI/GroupIndividualUI";
import PdfPreview from "../previews/PdfPreview";
import { useWebhook } from "../../../hooks/useWebhook";
import chatterStore from "../../../store/chatterStore";
import { MediaUploaderDownloader } from "./media-UI";
import WhatsAppImagePopup from "./image-open-pop-up";
import FilePreview from "./common-files-preview";

const MainChatArea =React.memo(({
  number,
  setNumber,
  chatter,
  setChatter,
  selectedChat,
  pusherData,
  setPusherData,
  chatsLoading,
  setChatLoading,
  setSelectedChat,
  darkMode,
  setGroupIndividualProfile,
  groupIndividualProfile,
  setChats,
  chats
}) => {

  const {getProfilepictureStore, profilePicture} = chatterStore();
  // console.log("selectedChat", { number, setNumber, chatter, selectedChat });

  const {data,isLoading, error} = useWebhook();
  const webhookData = data?.data

  const [db, setDb] = useState(null);

  const [webhookCurrentStatus, setWebhookCurrentStatus] = useState(true);

  const [lastSync, setLastSync] = useState(null);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupImageUrl, setPopupImageUrl] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [chatDetails, setChatDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [send, setSend] = useState(false);

  useEffect(()=>{
    (async () => {
      if(db){
        // const lastSyncTimeGet = async() => {
        //   let lastSyncTime = await getWebhookLastSyncTime(getWorkspaceId()); 
        //   // console.log('lastSyncTimeGet', lastSyncTime);
        //   setLastSync(lastSyncTime?.data?.webhook_ext);
        // }
        // lastSyncTimeGet();
        const recentChatters = filterRecentMessages(chatter, lastSync);
        // console.log('recentChatters', recentChatters);
  
        const finalObject = {};
    
        const finalArray = recentChatters.map((number)=> (
          getChatsByToProperty(db, 'chats', number?.id)
          .then((chats) => {
            // console.log("Fetched chats from IndexedDB:", chats);
            let filteringMessages = filterRecentChats(chats, lastSync);
            // console.log('filteringMessages', filteringMessages);
            let finalFilteringMessages = filteringMessages.map((message)=> {
              return message?.data;
            })
            finalObject[number?.id] = finalFilteringMessages
          })
          .catch((error) => {
            console.error("Error fetching chats:", error);
          })
        ))
  
        await Promise.all(finalArray)
        // console.log("FINAL RESPONSE", finalObject);

        // listenerForNewMessage(finalObject);
      }
    })();
  }, [chatter, db]) //try krna yeh chatter remove kar paoo

  function updateChatterLastMessage(chatter, newMessage) {
    // console.log('updateChatterLastMessage', {chatter, newMessage});
    
    chatter.conversationTimestamp = newMessage?.timestamp;
    chatter.unreadCount = 1;
    chatter.lastMessage = newMessage;

    return chatter;
  }

  function normalizeMessageId(messageId) {
    // Remove the part after the second underscore (_) including the number
    const parts = messageId.split("_");
    if (parts.length > 3) {
      // Reconstruct the normalized ID without the last part
      return `${parts[0]}_${parts[1]}_${parts[2]}`;
    }
    return messageId; // If already normalized, return as is
  }
  

  useEffect(() => {
    if (pusherData) {
        if (pusherData?.event === "message.reaction") {
          console.log('newMessage reaction before pusherData', pusherData);
        
          // Find the chat that matches the messageId
          let messageId = pusherData?.payload?.reaction?.messageId;
          console.log('messageId', messageId);
        
          if (selectedChat !== pusherData?.payload?.from) {
            // Attempt to get the message from IndexedDB
            getData(db, 'chats', messageId).then((chat) => {
              if (!chat) {
                // If not found, try with the modified ID
                const suffix = pusherData?.payload?.participate || "";
                messageId = `${messageId}_${suffix}`;
                console.log('Modified messageId with suffix', messageId);
                return getData(db, 'chats', messageId);
              }
              return chat;
            }).then((chat) => {
              if (chat) {
                console.log('chat', chat);
                const updatedChat = {
                  ...chat,
                  data: {
                    ...chat.data,
                    _data: {
                      ...chat.data?._data,
                      // reactions: updateReactions(pusherData?.payload?.reaction, pusherData?.payload?.reaction.text),
                      // reactions: [
                      //   ...(chat.data?._data?.reactions || []), // Keep existing reactions
                      //   updateReactions(chat.data?._data?.reactions, pusherData?.payload)         // Add the new reaction
                      // ],
                      reactions: updateReactions(chat.data?._data?.reactions, pusherData?.payload)
                    },
                  },
                };
                addData(db, 'chats', updatedChat); // Save the updated chat
                setChats(chats.map((c) => (c?.id === messageId ? updatedChat : c)));
              }
            });
          } else {
            // Update the state when the selected chat matches
            let updatedChats = chats.map((chat) => {
              if (chat?.id === messageId) {
                return {
                  ...chat,
                  data: {
                    ...chat.data,
                    _data: {
                      ...chat.data?._data,
                      // reactions: [
                      //   ...(chat.data?._data?.reactions || []), // Keep existing reactions
                      //   updateReactions(chat.data?._data?.reactions, pusherData?.data)         // Add the new reaction
                      // ],
                      reactions: updateReactions(chat.data?._data?.reactions, pusherData?.payload),
                    },
                  },
                };
              }
              return chat;
            });
        
            // If no matching chat found, try modifying the ID with the suffix
            if (!updatedChats.some((chat) => chat?.id === messageId)) {
              const suffix = pusherData?.payload?.participate || "";
              messageId = `${messageId}_${suffix}`;
              console.log('Modified messageId with suffix for state update', messageId);
              updatedChats = chats.map((chat) => {
                if (chat?.id === messageId) {
                  return {
                    ...chat,
                    data: {
                      ...chat.data,
                      _data: {
                        ...chat.data?._data,
                        // reactions: [
                        //   ...(chat.data?._data?.reactions || []), // Keep existing reactions
                        //   updateReactions(chat.data?._data?.reactions, pusherData?.data)         // Add the new reaction
                        // ],
                        reactions: updateReactions(chat.data?._data?.reactions, pusherData?.payload)
                      },
                    },
                  };
                }
                return chat;
              });
            }
        
            setChats(updatedChats); // Update the state with the modified chats array
        
            // Find the updated chat for IndexedDB
            const updatedChat = updatedChats.find((chat) => chat?.id === messageId);
            
            console.log('updatedChats', updatedChats);
            // Save the updated chat to IndexedDB
            if (updatedChat) {
              addData(db, "chats", updatedChat);
              setChats(chats.map((c) => (c?.id === messageId ? updatedChat : c)));
            }
          }
        }
        
        else{
          console.log('pusher newMessage.....1',pusherData);
    
          const newMessage = convertMessageObject(pusherData?.payload); //yeh kuch nhi kar rha
          // console.log('newMessage.....2',newMessage);
          let id = null;
          if(newMessage?.from.includes("@c.us")){
            id = newMessage?.from.replace("@c.us", "@s.whatsapp.net");
          }
          else{
            id = newMessage?.from;
          }
          // Find the chatter to update
          console.log('pusher', chatter);
          
          const chatterIndex = chatter.findIndex(c => c?.id === id);
          console.log('pusher chatterIndex', chatterIndex);
          
          if (chatterIndex !== -1) {
            const updatedChatter = updateChatterLastMessage(chatter[chatterIndex], newMessage);
            // console.log('updatedChatter', updatedChatter);
            
            const updatedChatterList = [
              updatedChatter,
              ...chatter.slice(0, chatterIndex),
              ...chatter.slice(chatterIndex + 1)
            ];
            
            console.log('pusher updatedChatterList', updatedChatterList);
            
            updatedChatterList.sort((a, b) => b.conversationTimestamp - a.conversationTimestamp);

            console.log('pusher updatedChatterList', updatedChatterList);
            // Set the updated chatter list
            setChatter(updatedChatterList);
          }

          if (chatterIndex === -1) {
            const newUser = convertChatObject(pusherData);
            console.log('pusher newUser', newUser);
            
            const updatedChatter = [newUser, ...chatter];
            console.log('pusher updatedChatter', updatedChatter);
            // const sortedChatter = updatedChatter.sort((a, b) => b.conversationTimestamp - a.conversationTimestamp);
            setChatter(updatedChatter);
          }
          
      
          // Prevent duplicate message in chats
          // console.log("Prevent duplicate message in chats", chats, newMessage);
          
          let messageExists = false;

          if(chats.length > 0 && chats[0]?.id.includes("@g.us")){
            console.log('@g.us', newMessage, chats);
            
            // messageExists = chats.some(chat => chat?.id === newMessage?.id);
            messageExists = chats.some(chat => newMessage?.id.includes(chat?.id));
          }
          else{
            messageExists = chats.some(chat => chat?.id === newMessage?.id);
            // console.log('messageExists', chats, newMessage?.id);
          }

          console.log('messageExists', messageExists);
          
          if (!messageExists) {
            if(selectedChat?.includes("@s.whatsapp.net")){
              selectedChat = selectedChat?.replace("@s.whatsapp.net", "@c.us");
            }
            else{
              selectedChat = selectedChat
            }
            
            if (
              !selectedChat?.includes(number) &&
              (selectedChat === pusherData?.payload?.from || selectedChat === pusherData?.payload?.to)
            ) {
              let finalAddMessage = {
                id: newMessage.id,
                data: newMessage
              }
              console.log('finalAddMessage', finalAddMessage);
              
              setChats([...chats, finalAddMessage]);
            }
            let chatter = {
              [newMessage?._data?.id?.remote] : [newMessage]
            }
            // listenerForNewMessage(chatter);
            // Add to IndexedDB if not already present
            addPusherDataIntoDB(newMessage);
          }
        }
    
        setPusherData(null);
    } 
  }, [pusherData, selectedChat, number]);

    // Find the selected chat details
    
    useEffect(()=>{
      console.log('Testing selectedChat', selectedChat);
      
      setChatDetails({});
      const setChatDetail = async()=> {

        if(selectedChat?.includes("@c.us")){
          selectedChat = selectedChat?.replace("@c.us", "@s.whatsapp.net");
        }
        else{
          selectedChat = selectedChat
        }

        // console.log('chatDetail', {selectedChat, chatter});

        let chatDetail = Array.isArray(chatter)
        ? chatter.find((c) => c?.id === selectedChat)
        : null;
        console.log('chatDetail 1', chatDetail, selectedChat);
        if(chatDetail === null || chatDetail === undefined){
          chatDetail = await getChatterInfo(number, selectedChat);
          console.log('chatDetail 2', chatDetail, selectedChat);
        }

        let profilePic = null;

        if (chatDetail) {
          let data = chatDetail;

          // Debounced update to ensure single re-render
          let newChatDetails = { data, profilePic: null };

          if (profilePicture?.[selectedChat]) {
            profilePic = profilePicture?.[selectedChat];
            newChatDetails.profilePic = profilePic;
            setChatDetails(newChatDetails);
          } else {
            setChatDetails(newChatDetails);

            getProfilepictureStore(number, number, selectedChat).then((result) => {
              console.log('result', result);
              
              profilePic = result?.profilePictureURL;
              setChatDetails({ ...newChatDetails, profilePic });
            }).catch((error) => {
              console.error('Error fetching profile picture:', error);
            });
          }
        }

        else {
          if (!selectedChat) return;
        
          try {
            let chatterInfo = await getChatterInfo(number, number, selectedChat);
            if (chatterInfo?.status === true) {
              let data = convertToMainObjectFormat(chatterInfo?.data);
        
              // Set initial state with chat data and null profile picture
              setChatDetails({ data, profilePic: null });
        
              if (profilePicture?.[selectedChat]) {
                // If profile picture is already available, update immediately
                let profilePic = profilePicture?.[selectedChat];
                setChatDetails({ data, profilePic });
              } else {
                // Fetch profile picture asynchronously
                getProfilepictureStore(number, number, selectedChat)
                  .then((result) => {
                    let profilePic = result?.profilePictureURL;
                    setChatDetails({ data, profilePic }); // Update state with fetched profile picture
                  })
                  .catch((error) => {
                    console.error("Error fetching profile picture:", error);
                  });
              }
            }
          } catch (error) {
            console.error("Error fetching chatter info:", error);
          }
        }
      }
      setChatDetail();
      // console.log("chatDetails", chatDetails);
    }, [selectedChat])

  
    useEffect(() => {
      const initializeDB = async () => {
        const dbName = "whatsappDB";
        const dbInstance = await openDB(dbName, 2);
        setDb(dbInstance);
      };
  
      initializeDB();
    }, []);

    useEffect(()=>{
      // fetchChatsFromDB(db, selectedChat)
      setChatLoading(true);
      // console.log("before selectedChat", selectedChat);
      if(selectedChat?.includes("@s.whatsapp.net")){
        selectedChat = selectedChat.replace("@s.whatsapp.net", "@c.us");
      }
      // console.log("after selectedChat", selectedChat);
      getChatsByToProperty(db, 'chats', selectedChat)
      .then((chats) => {
        // console.log("Fetched chats getChatsByToProperty", {selectedChat, chats});
        chats.sort((a, b) => b?.data?.timestamp - a?.data?.timestamp).reverse();
        setChats(chats); // Update the state with the fetched chats
        setChatLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chats:", error);
      });
    }, [selectedChat, db])
 
  // const listenerForNewMessage = async (chatter) => {
  //   // console.log("listenerForNewMessage messageData", chatter);

  //   // let chatter = {
  //   //   [messageData?._data?.id?.remote] : [messageData]
  //   // }

  //   if(chatter && webhookCurrentStatus){
  //     // const endpoint = webhookData?.endpoint;
  //     // console.log('webHook Data', webhookData);
      
  //     const endpoint = webhookData?.duplex_webhook ?? webhookData?.outgoing;
  //     const authToken = webhookData?.auth_token;

  //     if (endpoint) {
  //       sendEazybeWebhookMessageData({
  //           endPoint: endpoint,
  //           msgObject: chatter,
  //           authToken: authToken
  //       }).then((res) => {
  //           if (res) {
  //               sendWebhookSyncInfo(getWorkspaceId());
  //           }
  //       });
  //     } else {
  //       console.log("endpoit not found for webhook");
        
  //     }    
  //   }
  //   else{
  //     console.log("chatter or webhookCurrentStatus is not defined");
      
  //   }
  // };


  const getEazybeWebhookDataFromApi = (userInitialData) => {
    // console.log('getEazybeWebhookDataFromApi', userInitialData);
    
    return new Promise((resolve, reject) => {
        getEazybeWebhookData(userInitialData)
            .then((res) => {
                // console.log("getEazybeWebhookDataFromApi res", res);
                if (res?.data) {
                    setWebhookCurrentStatus(res.data.status === 1);
                    localStorage.setItem("isWebhookEnabled", res.data.status === 1);
                    resolve(res);
                } else {
                    setWebhookCurrentStatus(false);
                    localStorage.setItem("isWebhookEnabled", false);
                    resolve(false);
                }
            })
            .catch((err) => {
                console.error("Error fetching webhook data:", err);
                reject(err);
            });
    });
};


  const addPusherDataIntoDB = async (res) => {
    if (db) {
      // console.log("pusherData sentMessage", db, selectedChat);
      try {
        // const existingMessage = await getData(db, 'chats', selectedChat);
        // if (!existingMessage) {
          // console.log("pusherData sentMessage", { number, selectedChat, res });
          // console.log('db store 1', res);
          const chatterWithId = {
            // id: `${selectedChat}_${res.id}`,
            id: res.id,
            data: res,
          };
          // console.log("pusherData sentMessage", chatterWithId);

          // Add each chatter to the indexedDB
          addData(db, "chats", chatterWithId);
        // }
      } catch (error) {
        console.error("Error storing chats in indexedDB:", error);
      }
    }
  };

  function convertToMainObjectFormat(obj) {
    return {
        id: {
            server: obj?.id?.split('@')[1],
            user: obj.number,
            _serialized: obj.id,
        },
        name: obj.pushname || '',
        isGroup: obj.isGroup || false,
        unreadCount: 0,
        timestamp: Date.now() / 1000, // example timestamp
        archived: false,
        pinned: false,
        muteExpiration: 0,
        lastMessage: {
            _data: {
                id: {
                    fromMe: obj.isMe || false,
                    remote: obj.id,
                    id: Math.random().toString(36).substr(2, 16).toUpperCase(),
                    _serialized: `${obj.isMe || false}_${obj.id}_${Math.random().toString(36).substr(2, 16).toUpperCase()}`
                },
                viewed: false,
                body: '',
                type: 'text', // default type
                t: Date.now() / 1000, // example timestamp
                notifyName: obj.pushname || '',
                from: {
                    server: obj?.id?.split('@')[1],
                    user: obj.number,
                    _serialized: obj.id,
                },
                to: {
                    server: 'c.us',
                    user: obj.number,
                    _serialized: obj.id,
                },
                ack: 3,
                invis: false,
                star: false,
                kicNotified: false,
                deprecatedMms3Url: '',
                directPath: '',
                mimetype: '',
                filehash: '',
                encFilehash: '',
                size: 0,
                mediaKey: '',
                mediaKeyTimestamp: 0,
                isViewOnce: false,
                width: 0,
                height: 0,
                staticUrl: '',
                scanLengths: [],
                scansSidecar: {},
                isFromTemplate: false,
                pollInvalidated: false,
                isSentCagPollCreation: false,
                latestEditMsgKey: null,
                latestEditSenderTimestampMs: null,
                mentionedJidList: [],
                groupMentions: [],
                isEventCanceled: false,
                eventInvalidated: false,
                isVcardOverMmsDocument: false,
                isForwarded: false,
                labels: [],
                hasReaction: false,
                viewMode: 'VISIBLE',
                productHeaderImageRejected: false,
                lastPlaybackProgress: 0,
                isDynamicReplyButtonsMsg: false,
                isCarouselCard: false,
                parentMsgId: null,
                isMdHistoryMsg: false,
                stickerSentTs: 0,
                isAvatar: false,
                lastUpdateFromServerTs: 0,
                invokedBotWid: null,
                botTargetSenderJid: null,
                bizBotType: null,
                botResponseTargetId: null,
                botPluginType: null,
                botPluginReferenceIndex: null,
                botPluginSearchProvider: null,
                botPluginSearchUrl: null,
                botPluginSearchQuery: null,
                botPluginMaybeParent: false,
                botReelPluginThumbnailCdnUrl: null,
                botMsgBodyType: null,
                reportingTokenInfo: null,
                requiresDirectConnection: false,
                bizContentPlaceholderType: null,
                hostedBizEncStateMismatch: false,
                senderOrRecipientAccountTypeHosted: false,
                placeholderCreatedWhenAccountIsHosted: false,
                links: []
            },
            mediaKey: '',
            id: {
                fromMe: obj.isMe || false,
                remote: obj.id,
                id: Math.random().toString(36).substr(2, 16).toUpperCase(),
                _serialized: `${obj.isMe || false}_${obj.id}_${Math.random().toString(36).substr(2, 16).toUpperCase()}`
            },
            ack: 3,
            hasMedia: false,
            body: '',
            type: 'text', // default type
            timestamp: Date.now() / 1000,
            from: obj.isMe ? obj.id : 'self',
            to: obj.isMe ? 'self' : obj.id,
            deviceType: 'android',
            isForwarded: false,
            forwardingScore: 0,
            isStatus: false,
            isStarred: false,
            fromMe: obj.isMe || false,
            hasQuotedMsg: false,
            hasReaction: false,
            vCards: [],
            mentionedIds: [],
            groupMentions: [],
            isGif: false,
            links: []
        }
    };
  }

  useEffect(() => {
      if (!Object.keys(profilePicture).length) {
        return;
      }

      // console.log('profilePicture', profilePicture);

  },[profilePicture])

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };


  // Function to handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Phone Number:", e.target.value);
    // Here you can call your fetchWhatsappChatter API with the number
    closeModal(); // Close the modal after submission
  };


  const handleImageClick = (url) => {
    console.log("handleImageClick", url);
    
    setPopupImageUrl(url); // Set the image URL to display in the popup
    setIsPopupOpen(true); // Open the popup
    setIsOpen(true)
  };

// Main renderMessageContent function
const MediaContent = React.memo(({ media, mimetype, message }) => {
  const [renderCount, setRenderCount] = useState(0);
  const [mediaLoaded, setMediaLoaded] = useState(false);

  const updatedMediaUrl = useCallback(() => {
    return media?.url?.replace(
      "http://localhost:3000/",
      "https://waweb.eazybe.com/"
    );
  }, [media?.url]);

  const preloadMedia = useCallback(
    (url) => {
      if (!url || renderCount >= 5 || mediaLoaded) return;

      const mediaElement = new Image();
      mediaElement.src = url;

      mediaElement.onload = () => {
        setMediaLoaded(true);
        // console.log(`Media loaded successfully: ${url}`);
      };

      mediaElement.onerror = () => {
        console.error(`Failed to preload media: ${url}`);
        setRenderCount((prev) => prev + 1);
      };
    },
    [renderCount, mediaLoaded]
  );

  useEffect(() => {
    const url = updatedMediaUrl();
    if (url) {
      preloadMedia(url);
    }
  }, [updatedMediaUrl]);

  const handleError = async(e) => {
    const data  = await fetchChatOfChatterOrGroupByMessageId( number, message?.from, message?.id);

    const url = updatedMediaUrl();
    if (e.target.src !== url) {
      e.target.src = url; // Fallback
    } else {
      console.error("Fallback media also failed to load.");
    }
  };

  // Render the text body or caption
  const renderTextContent = () => {
    const text = media?.caption || message?.body || ""; // Use caption first, then body
    if (!text) return null;

    // Highlight URLs in the text
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);

    return (
      <p className="text-sm mb-2">
        {parts.map((part, index) =>
          urlRegex.test(part) ? (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              {part}
            </a>
          ) : (
            <span key={index}>{part}</span>
          )
        )}
      </p>
    );
  };

  // Render the media based on the mimetype
  const renderMediaContent = () => {
    if (mimetype?.includes("image")) {
      return (
        <>
        <img
          src={updatedMediaUrl()}
          alt="Image Message"
          className="w-[300px] object-contain rounded-lg cursor-pointer"
          onClick={() => handleImageClick(updatedMediaUrl())}
          onError={handleError}
        />
        {isPopupOpen && (
            <WhatsAppImagePopup isOpen={isOpen} setIsOpen={setIsOpen} imageUrl={popupImageUrl} alt="Sample landscape" />
        )}
        </>
      );
    }

    if (mimetype === "video/mp4") {
      return (
        <video
          src={updatedMediaUrl()}
          className="w-[300px] rounded-lg cursor-pointer dark:text-white"
          controls
          onError={handleError}
        >
          Your browser does not support the video tag.
        </video>
      );
    }

    if (mimetype?.includes("audio")) {
      return (
        <audio controls className="w-[300px] dark:text-white" onError={handleError}>
          <source src={updatedMediaUrl()} type={mimetype} />
          Your browser does not support the audio element.
        </audio>
      );
    }

    if (mimetype === "application/pdf") {
      return <PdfPreview pdfUrl={updatedMediaUrl()} fileName={"document"} />;
    }

    return <FilePreview fileName={media?.filename} fileType={media?.mimetype} fileSize={media?.size} downloadUrl={updatedMediaUrl()} />
  };

  // Main render
  return (
    <div className="media-content">
      {/* Render text content */}
      {renderTextContent()}

      {/* Render media content */}
      {renderMediaContent()}
    </div>
  );
});


const renderMessageContent = useCallback((message) => {
  // console.log('renderMessageContent', message);
  
  // Regular expression to match emoji characters
  const emojiRegex = /[\u{1F600}-\u{1F6FF}\u{1F300}-\u{1F5FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu;

  // Helper function to detect if a string contains only emojis
  const containsOnlyEmoji = (text) => {
    return emojiRegex.test(text) && text.replace(emojiRegex, '').trim() === '';
  };

  const mediaData = message?._data?.message;
  // Handle image, video, audio, and PDF messages
  if (message?.hasMedia) {
    return <MediaContent media={message?.media} mimetype={message?.media?.mimetype} message={message} />;
  } 
  else if (mediaData) {
    if (mediaData.videoMessage) {
      return (
        <MediaUploaderDownloader db={db} setChats={setChats} chats={chats} number={number} message={message} type="video" />
      );
    } else if (mediaData.imageMessage || mediaData?.documentMessage) {
      if(mediaData?.documentMessage?.mimetype?.includes("text/rtf")){
        return(
          <MediaUploaderDownloader db={db} setChats={setChats} chats={chats} number={number} message={message} type="document" handleImageClick={handleImageClick} />
        )
      }
      return (
        <MediaUploaderDownloader db={db} setChats={setChats} chats={chats} number={number} message={message} type="image" handleImageClick={handleImageClick} />
      );
    } else if (mediaData.documentWithCaptionMessage) {
      return (
        <MediaUploaderDownloader db={db} setChats={setChats} chats={chats} number={number} message={message} type="document"  />
      );
    } else if (mediaData.audioMessage) {
      return (
        <MediaUploaderDownloader db={db} setChats={setChats} chats={chats} number={number} message={message} type="audio" />
      );
    }
  }

  // Handle message body text with potential emojis
  if (message?.body) {
    const body = message.body;
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Split the message body into parts based on URLs and newlines
    const urlSplitParts = body.split(urlRegex);

    return (
      <>
        {/* Render reply details if available */}
        {message?.replyTo?.participant?.user || message?.replyTo?.body ? (
          <div className="border-l-4 flex flex-col pl-2 mb-2">
            {message?.replyTo?.participant?.user && (
              <span className="text-[0.7rem]">
                {message?.replyTo?.participant?.user}
              </span>
            )}
            {message?.replyTo?.body && (
              <span className="text-[0.7rem]">{message?.replyTo?.body}</span>
            )}
          </div>
        ) : null}

        {/* Render the main message body */}
        <p className="w-[90%] text-sm mb-[0.5rem]">
          {urlSplitParts.map((part, index) => {
            if (urlRegex.test(part)) {
              // Render URLs as clickable links
              return (
                <a
                  key={index}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {part}
                </a>
              );
            }

            // Handle line breaks
            const lines = part.split('\n');
            return lines.map((line, lineIndex) => {
              // Check if the line contains only emojis for special styling
              const isOnlyEmoji = containsOnlyEmoji(line);

              return (
                <span
                  key={`${index}-${lineIndex}`}
                  className={isOnlyEmoji ? 'text-4xl' : ''}
                >
                  {line}
                  {/* Add a line break unless it's the last line */}
                  {lineIndex < lines.length - 1 && <br />}
                </span>
              );
            });
          })}
        </p>
      </>
    );

  }

  // Handle link messages from extendedTextMessage
  if (message?._data?.message?.extendedTextMessage?.canonicalUrl) {
    const linkMessage = message._data.message.extendedTextMessage.canonicalUrl;

    return (
      <a
        href={linkMessage}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:underline text-sm"
      >
        {linkMessage}
      </a>
    );
  }

  // Handle other types of messages or return a default message
  return <p className="text-gray-500 text-sm mb-[0.5rem]">"Unsupported message format"</p>;
}, []);


  const renderMessageTicks = (message) => {
    // console.log('renderMessageTicks', message);
    
    if (message?.fromMe) {
      if (message?.ackName === "DEVICE") {
        return (
          <span aria-hidden="true" data-icon="status-dblcheck">
            <svg
              viewBox="0 0 18 18"
              height="18"
              width="18"
              preserveAspectRatio="xMidYMid meet"
              class=""
              version="1.1"
              x="0px"
              y="0px"
              enable-background="new 0 0 18 18"
            >
              <title>status-dblcheck</title>
              <path
                fill="#667681"
                d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"
              ></path>
            </svg>
          </span>
        );
      } else if (message?.ackName === "SERVER") {
        return (
          <span
            aria-hidden="false"
            aria-label=" Sent "
            data-icon="msg-check"
            class=""
          >
            <svg
              viewBox="0 0 12 11"
              height="11"
              width="16"
              preserveAspectRatio="xMidYMid meet"
              class=""
              fill="none"
            >
              <title>msg-check</title>
              <path
                d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z"
                fill="#667681"
              ></path>
            </svg>
          </span>
        );
      } else {
        return (
          <span
            aria-hidden="false"
            aria-label=" Read "
            data-icon="msg-dblcheck"
            class="x1q15gih"
          >
            <svg
              viewBox="0 0 16 11"
              height="11"
              width="16"
              preserveAspectRatio="xMidYMid meet"
              class=""
              fill="none"
            >
              <title>msg-dblcheck</title>
              <path
                d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                fill="#53BDEA"
              ></path>
            </svg>
          </span>
        );
      }
    }
    return null;
  };

  const [isScrollLoading, setIsScrollLoading] = useState(true);
  
  const chatEndRef = useRef(null);
  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chats]);

  useEffect(() => {
    setIsScrollLoading(true);

    setTimeout(() => {
      setIsScrollLoading(false);
    }, 2000);

  }, [chatDetails, selectedChat]);

  const scrollToBottom = () => {
    // console.log('scrollToBottom');
    
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [scroll, setScroll] = useState(true);
  const chatContainerRef = useRef(null);
  useEffect(() => {
    const chatContainer = chatContainerRef?.current;

    const handleScroll = (event) => {
      // Log a message every time a scroll event happens
      // console.log('Scrolling...');
      setScroll(true)
      if (chatContainer) {
        const { scrollTop, scrollHeight, clientHeight } = chatContainer;

        // Log the scroll position details
        // console.log('Scroll Position:', {
        //   scrollTop,
        //   scrollHeight,
        //   clientHeight,
        // });

        // Check if user scrolled to the bottom
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          // console.log('Scrolled to bottom');
          // Load more messages or perform another action
        }
      }
    };

    // Attach scroll event listener
    chatContainer?.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      chatContainer?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderReactions = (reactions) => {
    if (!Array.isArray(reactions)) return null;
  
    // Group reactions by text (emoji)
    const reactionCounts = reactions.reduce((acc, reaction) => {
      if (reaction?.text) {
        acc[reaction.text] = (acc[reaction.text] || 0) + 1;
      }
      return acc;
    }, {});
  
    // Display each reaction with its count
    return (
      <div className="absolute bottom-[-15px] left-[20px] flex space-x-2">
        {Object.entries(reactionCounts).map(([reaction, count]) => (
          <span
            key={reaction} // Unique key for each reaction
            className="flex items-center bg-white border border-gray-100 rounded-xl px-2 py-1"
          >
            <span>{reaction}</span>
            {count > 1 && <span className="text-xs ml-1">{count}</span>}
          </span>
        ))}
      </div>
    );
  };  

  const [searchQuery, setSearchQuery] = useState([]);
  const [enableSearchBar, setEnableSearchBar] = useState(false);

  // useEffect(()=> {
  //   console.log('enableSearchBar', {enableSearchBar, searchQuery});
  // }, [enableSearchBar, searchQuery])

  const getRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return `#${randomColor.padStart(6, '0')}`; // Generate hex color code
  };
  
  const [toggleReaction, setToggleReaction] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const toggleEmojiPopup = (message) => {
    // console.log('toggleEmojiPopup', message);
    if (selectedMessageId === message.id) {
      setSelectedMessageId(null); // Close if the same message is clicked again
      setToggleReaction(false);
    } else {
      setSelectedMessageId(message.id); // Set the clicked message ID
      setToggleReaction(true);
    }
  };

  const [toggleMessageOption, setToggleMessageOption] = useState(false);
  const toggleMessageOptionsPopup = (message) => {
    // console.log('toggleEmojiPopup', message);
    
    if (selectedMessageId === message.id) {
      setSelectedMessageId(null); // Close if the same message is clicked again
      setToggleMessageOption(false);
    } else {
      setSelectedMessageId(message.id); // Set the clicked message ID
      setToggleMessageOption(true);
    }
  };

  const { t } = useTranslation();
  // console.log('chatssss', chats);
  
  const giveReaction = async (message, emoji) => {
    // console.log("giveReaction", message);
    // console.log("giveReaction", emoji);
    setSelectedMessageId(null);
  
    let body = {
      messageId: message.id,
      reaction: emoji,
      session: number,
    };
  
    // console.log("giveReaction", body);
    let res = await reactEmoji(body, number);
    // console.log("res", res);
  
    // if (res.status) {
    //   setChats((prevChats) => {
    //     const updatedChats = Array.isArray(prevChats)
    //       ? prevChats.map((msg) =>
    //           msg.id === message.id
    //             ? {
    //                 ...msg,
    //                 reactions: updateReactions(msg?.reactions, emoji),
    //               }
    //             : msg
    //         )
    //       : []; // Fallback to an empty array if prevChats is null or not an array
      
    //     // Find the updated message
    //     const updatedMessage = updatedChats.find((msg) => msg.id === message.id);
      
    //     // Push the updated message to the database
    //     if (updatedMessage) {
    //       addData(db, "chats", updatedMessage);
    //     }
      
    //     return updatedChats;
    //   });
      
    // } else {
    //   toast.error("Not able to react to a message right now");
    // }
    
  };
  
  // Function to update the reactions by emoji count
  // const updateReactions = (reactions = [], emoji) => {

  //   const updatedReactions = null;
  //   if(reactions?.length > 0) {
  //     updateReactions = [...reactions];
  //   }
  //    // Copy the current reactions
  
  //   // Find if the emoji already exists in the array
  //   const existingReactionIndex = updatedReactions?.findIndex(
  //     (reaction) => reaction?.text === emoji
  //   );
  
  //   if (existingReactionIndex !== -1) {
  //     // If emoji exists, increment its count
  //     updatedReactions[existingReactionIndex].count += 1;
  //   } else {
  //     // If emoji doesn't exist, add it to the reactions
  //     updatedReactions.push({ text: emoji, count: 1 });
  //   }
  
  //   return updatedReactions;
  // };

 
  

  const [getChatter, setGetChatter] = useState({});
  const [loading, setLoading] = useState(false);
  const handleGroupIndividualProfile = async(message)=> {
    setLoading(true)
    console.log('handleGroupIndividualProfile',message);
    setGroupIndividualProfile(true);
    
    // console.log('handleGroupIndividualProfile', chatter);
    let chatterInfo = await getChatterInfo(number, message?.data?.participant)
    let profilePic = await getProfilepictureStore(number, number, message?.data?.participant);
    
    const data = chatterInfo || {}; // Default to an empty object if data is undefined
    setGetChatter({ data, profilePic });
    setLoading(false);

  }

  console.log('mainchatarea', {selectedChat, chats});
  
  
  return (
    <>
    {!chatsLoading ? 
      (
        <div className="flex-1 flex flex-col">
          {selectedChat ? (
            selectedChat.includes("g.us") ? (
              <>
                <TopBar setGroupIndividualProfile={setGroupIndividualProfile} setSelectedChat={setSelectedChat} enableSearchBar={enableSearchBar} setEnableSearchBar={setEnableSearchBar} chats={chats} setSearchQuery={setSearchQuery} chatDetails={chatDetails} />
                {
                    isScrollLoading && (
                      <div id="loading-overlay" className="text-gray-500 dark:text-gray-400 text-lg gap-2 absolute bg-white top-0 bottom-0 w-[70%] flex items-center justify-center z-10">
                        Loading Chats
                        <ClipLoader />
                      </div>  
                    )
                }
                <div
                  ref={chatContainerRef}
                  className="flex-1 w-[100%] overflow-y-auto p-4 bg-[#e5ddd5] dark:bg-gray-700"
                  style={{
                    backgroundImage: darkMode ? `url(${backgroundImageBlack})` :  `url(${backgroundImageWhite})`,
                    backgroundRepeat: "repeat",
                  }}
                >
                  {/* Group chat messages would go here */}
                  <div className="flex flex-col space-y-2 w-[100%] ">
                    {Array.isArray(searchQuery) && searchQuery?.length > 0 ? 
                    (
                      searchQuery
                        ?.slice()
                        // ?.reverse()
                        ?.map((message) => (
                          <>
                              <div
                                key={message.id}
                                className={`flex items-center group relative ${
                                  message?.data?.fromMe ? "justify-end" : "justify-start"
                                }`}
                              >
                                {message?.data?.fromMe ? 
                                  (
                                  <div className="relative">
                                    <div onClick={()=> toggleEmojiPopup(message)} className="hidden group-hover:inline-block mr-3 mt-2 relative group-hover:rounded-full group-hover:bg-[#BDBBB6] group-hover:h-6 group-hover:w-6 group-hover:flex group-hover:justify-center group-hover:items-center">
                                      <span aria-hidden="true" data-icon="react" className="text-white"><svg viewBox="0 0 15 15" width="15" preserveAspectRatio="xMidYMid meet" fill="none"><title>react</title><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 11.6305 3.36946 15 7.5 15C11.6527 15 15 11.6305 15 7.5C15 3.36946 11.6305 0 7.5 0C3.36946 0 0 3.36946 0 7.5ZM10.995 8.69333C11.1128 8.67863 11.2219 8.66503 11.3211 8.65309C11.61 8.63028 11.8076 8.91918 11.6784 9.13965C10.8573 10.6374 9.29116 11.793 7.50455 11.793C5.71794 11.793 4.15181 10.6602 3.33072 9.16246C3.18628 8.91918 3.37634 8.63028 3.66524 8.65309C3.79123 8.66749 3.93521 8.68511 4.09426 8.70457C4.94292 8.80842 6.22074 8.96479 7.48174 8.96479C8.81855 8.96479 10.1378 8.80025 10.995 8.69333ZM5.41405 7.37207C6.05761 7.37207 6.60923 6.72851 6.60923 6.02978C6.60923 5.30348 6.05761 4.6875 5.41405 4.6875C4.77048 4.6875 4.21886 5.33106 4.21886 6.02978C4.20967 6.75609 4.77048 7.37207 5.41405 7.37207ZM10.7807 6.05619C10.7807 6.74114 10.24 7.37201 9.60912 7.37201C8.97825 7.37201 8.4375 6.76818 8.4375 6.05619C8.4375 5.37124 8.97825 4.74037 9.60912 4.74037C10.24 4.74037 10.7807 5.34421 10.7807 6.05619Z" fill="currentColor"></path></svg></span>
                                    </div>
                                    {selectedMessageId === message.id && toggleReaction && (
                                      <div className="absolute top-[-3rem] z-10 right-[2rem] z-10 flex space-x-1 bg-white shadow-lg border border-gray-200 p-2 rounded-md">
                                        {["👍", "❤️", "😂", "😮", "😢", "👏"].map((emoji, index) => (
                                          <button onClick={() => {
                                            // console.log("Selected emoji:", emoji); // Log emoji to see if it’s defined
                                            giveReaction(message, emoji); // Call giveReaction with message and emoji
                                          }}
                                          key={index} className="text-xl hover:bg-gray-200 p-1 rounded-full">
                                            {emoji}
                                          </button>
                                        ))}
                                      </div>
                                    )}
                                  </div>

                                  ) : (null)
                                }
                                <div
                                  className={`p-2 flex justify-between max-w-[80%] flex-col break-words relative ${
                                    message?.data?.fromMe
                                      ? "bg-[#D9FDD2] dark:bg-[#015C4B] dark:text-white text-black rounded-[10px] item-center"
                                      : "bg-white dark:bg-[#1F2C33] dark:text-white text-gray-800 rounded-tr-lg rounded-br-lg rounded-bl-lg rounded-tl-none flex-col gap-1"
                                  }`}
                                  style={{
                                    minWidth: "220px",
                                    maxWidth: "60%",
                                    wordBreak: "break-word",
                                    padding: "8px 12px",
                                    marginTop: "10px"
                                  }}
                                > 
                                  {!message?.data?.fromMe ?  
                                  (<div className="absolute top-0 left-[-8px] text-white block w-2 h-3">
                                    <span
                                      aria-hidden="true"
                                      data-icon="tail-in"
                                      className="absolute top-0 z-[var(--layer-1)] block w-2 h-3"
                                    >
                                      <svg
                                        viewBox="0 0 8 13"
                                        height="13"
                                        width="8"
                                        preserveAspectRatio="xMidYMid meet"
                                        class=""
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        enable-background="new 0 0 8 13"
                                      >
                                        <title>tail-in</title>
                                        <path
                                          opacity="0.13"
                                          fill="#0000000"
                                          d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"
                                        ></path>
                                        <path
                                          fill="currentColor"
                                          d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"
                                        ></path>
                                      </svg>
                                    </span>
                                  </div>)
                                  :
                                  null
                                  }
                                  {
                                    message?.data?.fromMe ? 
                                    (null) 
                                    : 
                                    (
                                      <div className="flex justify-between h-[20px]">
                                        <span className="hover:cursor-pointer text-xs" style={{ color: getRandomColor() }} onClick={()=> handleGroupIndividualProfile(message)}>
                                          {message?.data?.fromMe ? '' : `~${message?.data?._data?.pushName || message?.data?._data?.participant?.split("@")[0] || message?.data?.participant?.split("@")[0] || message?.data?.key?.participant?.split("@")[0]}`}
                                        </span>
                                        {/* <span onClick={()=> toggleMessageOptionsPopup(message)} className="hover:cursor-pointer">
                                          <span aria-hidden="true" data-icon="down-context" className="hidden group-hover:inline-block"><svg viewBox="0 0 18 18" height="18" width="18" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 18 18"><title>down-context</title><path fill="#A9B4BB" d="M3.3,4.6L9,10.3l5.7-5.7l1.6,1.6L9,13.4L1.7,6.2L3.3,4.6z"></path></svg></span>
                                        </span> */}
                                      </div>
                                    )
                                  }
                                  <div className={`flex flex-col ${message?.data?.fromMe ? "pt-[0.5rem]"  : ""}`}>
                                    {renderMessageContent(message?.data)}
                                    <span className="text-xs dark:text-[#A3BEB7] text-gray-500 block absolute right-[5px] bottom-[5px] flex items-center">
                                      {getLastMessageTime(message?.data?.timestamp)}
                                      {renderMessageTicks(message?.data)}
                                    </span>
                                  </div>
                                  {Array.isArray(message?.data?._data?.reactions) &&
                                    renderReactions(message?.data?._data?.reactions)
                                  }
                                </div>

                                {message?.data?.fromMe ? 
                                  (null) : (
                                    <div className="relative">
                                      <div onClick={()=> toggleEmojiPopup(message)} className="hidden group-hover:inline-block ml-3 mt-2 relative group-hover:rounded-full group-hover:bg-[#BDBBB6] group-hover:h-6 group-hover:w-6 group-hover:flex group-hover:justify-center group-hover:items-center">
                                        <span aria-hidden="true" data-icon="react" className="text-white"><svg viewBox="0 0 15 15" width="15" preserveAspectRatio="xMidYMid meet" fill="none"><title>react</title><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 11.6305 3.36946 15 7.5 15C11.6527 15 15 11.6305 15 7.5C15 3.36946 11.6305 0 7.5 0C3.36946 0 0 3.36946 0 7.5ZM10.995 8.69333C11.1128 8.67863 11.2219 8.66503 11.3211 8.65309C11.61 8.63028 11.8076 8.91918 11.6784 9.13965C10.8573 10.6374 9.29116 11.793 7.50455 11.793C5.71794 11.793 4.15181 10.6602 3.33072 9.16246C3.18628 8.91918 3.37634 8.63028 3.66524 8.65309C3.79123 8.66749 3.93521 8.68511 4.09426 8.70457C4.94292 8.80842 6.22074 8.96479 7.48174 8.96479C8.81855 8.96479 10.1378 8.80025 10.995 8.69333ZM5.41405 7.37207C6.05761 7.37207 6.60923 6.72851 6.60923 6.02978C6.60923 5.30348 6.05761 4.6875 5.41405 4.6875C4.77048 4.6875 4.21886 5.33106 4.21886 6.02978C4.20967 6.75609 4.77048 7.37207 5.41405 7.37207ZM10.7807 6.05619C10.7807 6.74114 10.24 7.37201 9.60912 7.37201C8.97825 7.37201 8.4375 6.76818 8.4375 6.05619C8.4375 5.37124 8.97825 4.74037 9.60912 4.74037C10.24 4.74037 10.7807 5.34421 10.7807 6.05619Z" fill="currentColor"></path></svg></span>
                                      </div>
                                      {selectedMessageId === message.id && toggleReaction && (
                                        <div className="absolute top-[-3rem] left-[3rem] z-10 z-10 flex space-x-1 bg-white shadow-lg border border-gray-200 p-2 rounded-md">
                                          {["👍", "❤️", "😂", "😮", "😢", "👏"].map((emoji, index) => (
                                            <button onClick={() => {
                                              // console.log("Selected emoji:", emoji); // Log emoji to see if it’s defined
                                              giveReaction(message, emoji); // Call giveReaction with message and emoji
                                            }}
                                            key={index} className="text-xl hover:bg-gray-200 p-1 rounded-full">
                                              {emoji}
                                            </button>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  )
                                }
                                
                              </div>
                          </>
                        ))
                    ) : 
                    (
                    <>
                    {
                      Array.isArray(chats) && chats.length > 0 ? 
                      (
                        chats
                        ?.slice()
                        // ?.reverse()
                        ?.map((message) => (
                          <>
                              <div
                                key={message.id}
                                className={`flex items-center group relative ${
                                  message?.data?.fromMe ? "justify-end" : "justify-start"
                                }`}
                              >
                            {message?.data?.fromMe ? 
                              ( <div className="relative">
                                  <div onClick={()=> toggleEmojiPopup(message)} className="hidden group-hover:inline-block mr-3 mt-2 relative group-hover:rounded-full group-hover:bg-[#BDBBB6] group-hover:h-6 group-hover:w-6 group-hover:flex group-hover:justify-center group-hover:items-center">
                                    <span aria-hidden="true" data-icon="react" className="text-white"><svg viewBox="0 0 15 15" width="15" preserveAspectRatio="xMidYMid meet" fill="none"><title>react</title><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 11.6305 3.36946 15 7.5 15C11.6527 15 15 11.6305 15 7.5C15 3.36946 11.6305 0 7.5 0C3.36946 0 0 3.36946 0 7.5ZM10.995 8.69333C11.1128 8.67863 11.2219 8.66503 11.3211 8.65309C11.61 8.63028 11.8076 8.91918 11.6784 9.13965C10.8573 10.6374 9.29116 11.793 7.50455 11.793C5.71794 11.793 4.15181 10.6602 3.33072 9.16246C3.18628 8.91918 3.37634 8.63028 3.66524 8.65309C3.79123 8.66749 3.93521 8.68511 4.09426 8.70457C4.94292 8.80842 6.22074 8.96479 7.48174 8.96479C8.81855 8.96479 10.1378 8.80025 10.995 8.69333ZM5.41405 7.37207C6.05761 7.37207 6.60923 6.72851 6.60923 6.02978C6.60923 5.30348 6.05761 4.6875 5.41405 4.6875C4.77048 4.6875 4.21886 5.33106 4.21886 6.02978C4.20967 6.75609 4.77048 7.37207 5.41405 7.37207ZM10.7807 6.05619C10.7807 6.74114 10.24 7.37201 9.60912 7.37201C8.97825 7.37201 8.4375 6.76818 8.4375 6.05619C8.4375 5.37124 8.97825 4.74037 9.60912 4.74037C10.24 4.74037 10.7807 5.34421 10.7807 6.05619Z" fill="currentColor"></path></svg></span>
                                  </div>
                                  {selectedMessageId === message.id && toggleReaction && (
                                    <div className="absolute top-[-3rem] right-[2rem] z-10 flex space-x-1 bg-white shadow-lg border border-gray-200 p-2 rounded-md">
                                      {["👍", "❤️", "😂", "😮", "😢", "👏"].map((emoji, index) => (
                                        <button onClick={() => {
                                          // console.log("Selected emoji:", emoji); // Log emoji to see if it’s defined
                                          giveReaction(message, emoji); // Call giveReaction with message and emoji
                                        }}
                                         key={index} className="text-xl hover:bg-gray-200 p-1 rounded-full">
                                          {emoji}
                                        </button>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ) : (null)
                            }
                            <div
                              className={`p-2 flex justify-between max-w-[80%] flex-col break-words relative ${
                                message?.data?.fromMe
                                  ? "bg-[#D9FDD2] dark:bg-[#015C4B] dark:text-white text-black rounded-[10px] item-center"
                                  : "bg-white dark:bg-[#1F2C33] dark:text-white text-gray-800 rounded-tr-lg rounded-br-lg rounded-bl-lg rounded-tl-none flex-col gap-1"
                              }`}
                              style={{
                                minWidth: "220px",
                                maxWidth: "60%",
                                wordBreak: "break-word",
                                padding: "8px 12px",
                                marginTop: "10px",
                              }}
                            > 
                              {!message?.data?.fromMe ?  
                              (<div className="absolute top-0 left-[-8px] text-white block w-2 h-3">
                                <span
                                  aria-hidden="true"
                                  data-icon="tail-in"
                                  className="absolute top-0 z-[var(--layer-1)] block w-2 h-3"
                                >
                                  <svg
                                    viewBox="0 0 8 13"
                                    height="13"
                                    width="8"
                                    preserveAspectRatio="xMidYMid meet"
                                    class=""
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    enable-background="new 0 0 8 13"
                                  >
                                    <title>tail-in</title>
                                    <path
                                      opacity="0.13"
                                      fill="#0000000"
                                      d="M1.533,3.568L8,12.193V1H2.812 C1.042,1,0.474,2.156,1.533,3.568z"
                                    ></path>
                                    <path
                                      fill="currentColor"
                                      d="M1.533,2.568L8,11.193V0L2.812,0C1.042,0,0.474,1.156,1.533,2.568z"
                                    ></path>
                                  </svg>
                                </span>
                              </div>)
                              :
                              null
                              }
                              {/* <p className="text-[#929DA5] text-xs">
                                {message?.data?.fromMe ? '' : `~${message?.data?._data?.author?.user}`}
                              </p> */}
                              {
                                    message?.data?.fromMe ? 
                                    (null) 
                                    : 
                                    (
                                      <div className="flex justify-between h-[20px]">
                                        <span className="hover:cursor-pointer text-xs" style={{ color: getRandomColor() }} onClick={()=> handleGroupIndividualProfile(message)}>
                                          {message?.data?.fromMe ? '' : `~${message?.data?._data?.pushName || message?.data?._data?.participant?.split("@")[0] || message?.data?.participant?.split("@")[0]}`}
                                        </span>
                                        {/* <span onClick={()=> toggleMessageOptionsPopup(message)} className="hover:cursor-pointer">
                                          <span aria-hidden="true" data-icon="down-context" className="hidden group-hover:inline-block"><svg viewBox="0 0 18 18" height="18" width="18" preserveAspectRatio="xMidYMid meet" class="" version="1.1" x="0px" y="0px" enable-background="new 0 0 18 18"><title>down-context</title><path fill="#A9B4BB" d="M3.3,4.6L9,10.3l5.7-5.7l1.6,1.6L9,13.4L1.7,6.2L3.3,4.6z"></path></svg></span>
                                        </span> */}
                                      </div>
                                    )
                                  }
                              <div className={`flex flex-col ${message?.data?.fromMe ? "pt-[0.5rem]"  : ""}`}>
                                {renderMessageContent(message?.data)}
                                <span className="text-xs dark:text-[#A3BEB7] text-gray-500 block absolute right-[5px] bottom-[5px] flex items-center">
                                  {getLastMessageTime(message?.data?.timestamp)}
                                  {renderMessageTicks(message?.data)}
                                </span>
                              </div>
                              {Array.isArray(message?.data?._data?.reactions) &&
                                renderReactions(message?.data?._data?.reactions)
                              }
                            </div>
                            {message?.data?.fromMe ? 
                              (null) : (
                                <div className="relative">
                                  <div onClick={()=> toggleEmojiPopup(message)} className="hidden group-hover:inline-block ml-3 mt-2 relative group-hover:rounded-full group-hover:bg-[#BDBBB6] group-hover:h-6 group-hover:w-6 group-hover:flex group-hover:justify-center group-hover:items-center">
                                    <span aria-hidden="true" data-icon="react" className="text-white"><svg viewBox="0 0 15 15" width="15" preserveAspectRatio="xMidYMid meet" fill="none"><title>react</title><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 11.6305 3.36946 15 7.5 15C11.6527 15 15 11.6305 15 7.5C15 3.36946 11.6305 0 7.5 0C3.36946 0 0 3.36946 0 7.5ZM10.995 8.69333C11.1128 8.67863 11.2219 8.66503 11.3211 8.65309C11.61 8.63028 11.8076 8.91918 11.6784 9.13965C10.8573 10.6374 9.29116 11.793 7.50455 11.793C5.71794 11.793 4.15181 10.6602 3.33072 9.16246C3.18628 8.91918 3.37634 8.63028 3.66524 8.65309C3.79123 8.66749 3.93521 8.68511 4.09426 8.70457C4.94292 8.80842 6.22074 8.96479 7.48174 8.96479C8.81855 8.96479 10.1378 8.80025 10.995 8.69333ZM5.41405 7.37207C6.05761 7.37207 6.60923 6.72851 6.60923 6.02978C6.60923 5.30348 6.05761 4.6875 5.41405 4.6875C4.77048 4.6875 4.21886 5.33106 4.21886 6.02978C4.20967 6.75609 4.77048 7.37207 5.41405 7.37207ZM10.7807 6.05619C10.7807 6.74114 10.24 7.37201 9.60912 7.37201C8.97825 7.37201 8.4375 6.76818 8.4375 6.05619C8.4375 5.37124 8.97825 4.74037 9.60912 4.74037C10.24 4.74037 10.7807 5.34421 10.7807 6.05619Z" fill="currentColor"></path></svg></span>
                                  </div>
                                  {selectedMessageId === message.id && toggleReaction && (
                                    <div className="absolute top-[-3rem] left-[3rem] z-10 flex space-x-1 bg-white shadow-lg border border-gray-200 p-2 rounded-md">
                                      {["👍", "❤️", "😂", "😮", "😢", "👏"].map((emoji, index) => (
                                        <button onClick={() => {
                                          // console.log("Selected emoji:", emoji); // Log emoji to see if it’s defined
                                          giveReaction(message, emoji); // Call giveReaction with message and emoji
                                        }}
                                         key={index} className="text-xl hover:bg-gray-200 p-1 rounded-full">
                                          {emoji}
                                        </button>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )
                            }
                          </div>
                          </>
                        ))
                      ) 
                      : 
                      (null)
                    }
                    </>
                    )}
                    {scroll && 
                    <button onClick={scrollToBottom} className="transform rotate-90 fixed bottom-[10rem] right-[3rem] bg-white p-1 rounded-full shadow-lg transition-all">
                      <span aria-hidden="true" data-icon="chevron" class="x1h7gb8d x1rg5ohu x1iffjtl"><svg viewBox="0 0 30 30" height="30" width="30" preserveAspectRatio="xMidYMid meet" class="" x="0px" y="0px"><title>chevron</title><path fill="#888D90" d="M11,21.212L17.35,15L11,8.65l1.932-1.932L21.215,15l-8.282,8.282L11,21.212z"></path></svg></span>
                    </button>}
                    <div ref={chatEndRef}></div>
                  </div>
                </div>
                {chatDetails?.data?.groupMetadata?.announce ? 
                <OnlyAdminMessage />
                : 
                  <SentMessage
                    number={number}
                    selectedChat={selectedChat}
                    allChats={chats}
                    setAllChats={setChats}
                    setSend={setSend}
                  />
                }
              </>
            ) : (
              <>
                <TopBar setGroupIndividualProfile={setGroupIndividualProfile} setSelectedChat={setSelectedChat} enableSearchBar={enableSearchBar} setEnableSearchBar={setEnableSearchBar} chats={chats} setSearchQuery={setSearchQuery} chatDetails={chatDetails} />
                {
                    isScrollLoading && (
                      <div id="loading-overlay" className="text-gray-500 dark:text-gray-400 text-lg gap-2 absolute bg-white top-0 bottom-0 w-[70%] flex items-center justify-center z-10">
                        Loading Chats
                        <ClipLoader />
                      </div>  
                    )
                }
                <div
                  ref={chatContainerRef}
                  className="flex-1 overflow-y-auto p-4 bg-[#e5ddd5] dark:bg-gray-700"
                  style={{
                    backgroundImage: darkMode ? `url(${backgroundImageBlack}) ` : `url(${backgroundImageWhite})`,
                    backgroundRepeat: "repeat",
                  }}
                >
                  {/* Individual chat messages would go here */}
                  <div className="flex flex-col space-y-2">
                    {Array.isArray(searchQuery) && searchQuery.length > 0 && enableSearchBar ? (
                      searchQuery
                        ?.slice()
                        // ?.reverse()
                        ?.map((message) => (
                          <>
                              <div
                                key={message.id}
                                className={`flex items-center group relative  ${
                                  message?.data?.fromMe ? "justify-end" : "justify-start"
                                }`}
                              >
                                {message?.data?.fromMe ? 
                                  (
                                  <div className="relative">
                                    <div onClick={()=> toggleEmojiPopup(message)} className="hidden group-hover:inline-block mr-3 mt-2 relative group-hover:rounded-full group-hover:bg-[#BDBBB6] group-hover:h-6 group-hover:w-6 group-hover:flex group-hover:justify-center group-hover:items-center">
                                      <span aria-hidden="true" data-icon="react" className="text-white"><svg viewBox="0 0 15 15" width="15" preserveAspectRatio="xMidYMid meet" fill="none"><title>react</title><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 11.6305 3.36946 15 7.5 15C11.6527 15 15 11.6305 15 7.5C15 3.36946 11.6305 0 7.5 0C3.36946 0 0 3.36946 0 7.5ZM10.995 8.69333C11.1128 8.67863 11.2219 8.66503 11.3211 8.65309C11.61 8.63028 11.8076 8.91918 11.6784 9.13965C10.8573 10.6374 9.29116 11.793 7.50455 11.793C5.71794 11.793 4.15181 10.6602 3.33072 9.16246C3.18628 8.91918 3.37634 8.63028 3.66524 8.65309C3.79123 8.66749 3.93521 8.68511 4.09426 8.70457C4.94292 8.80842 6.22074 8.96479 7.48174 8.96479C8.81855 8.96479 10.1378 8.80025 10.995 8.69333ZM5.41405 7.37207C6.05761 7.37207 6.60923 6.72851 6.60923 6.02978C6.60923 5.30348 6.05761 4.6875 5.41405 4.6875C4.77048 4.6875 4.21886 5.33106 4.21886 6.02978C4.20967 6.75609 4.77048 7.37207 5.41405 7.37207ZM10.7807 6.05619C10.7807 6.74114 10.24 7.37201 9.60912 7.37201C8.97825 7.37201 8.4375 6.76818 8.4375 6.05619C8.4375 5.37124 8.97825 4.74037 9.60912 4.74037C10.24 4.74037 10.7807 5.34421 10.7807 6.05619Z" fill="currentColor"></path></svg></span>
                                    </div>
                                    {selectedMessageId === message.id && toggleReaction && (
                                      <div className="absolute top-[-3rem] right-[2rem] z-10 flex space-x-1 bg-white shadow-lg border border-gray-200 p-2 rounded-md">
                                        {["👍", "❤️", "😂", "😮", "😢", "👏"].map((emoji, index) => (
                                          <button onClick={() => {
                                            // console.log("Selected emoji:", emoji); // Log emoji to see if it’s defined
                                            giveReaction(message, emoji); // Call giveReaction with message and emoji
                                          }}
                                          key={index} className="text-xl hover:bg-gray-200 p-1 rounded-full">
                                            {emoji}
                                          </button>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                  ) : (null)
                                }
                                <div
                                  className={`p-2 flex justify-between flex-col rounded-lg max-w-[80%] break-words relative ${
                                    message?.data?.fromMe
                                      ? "bg-[#D9FDD2] dark:bg-[#015C4B] dark:text-white text-black"
                                      : "bg-white dark:bg-[#1F2C33] dark:text-white text-gray-800"
                                  }`}
                                  style={{
                                    minWidth: "220px",
                                    maxWidth: "60%",
                                    wordBreak: "break-word",
                                    padding: "8px 12px",
                                    borderRadius: "10px",
                                    // minHeight: "70px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div className="flex flex-col pt-[0.5rem]">
                                  {renderMessageContent(message.data)}
                                  <span className="text-xs dark:text-[#A3BEB7] text-gray-500 block absolute right-[5px] bottom-[5px] flex items-center">
                                    {getLastMessageTime(message?.data?.timestamp)}
                                    {renderMessageTicks(message.data)}
                                  </span>
                                  </div>
                                  
                                  {Array.isArray(message?.data?._data?.reactions) &&
                                    renderReactions(message?.data?._data?.reactions)}
                                </div>
                                {message?.data?.fromMe ? 
                                  (null) : (
                                    <div className="relative">
                                    <div onClick={()=> toggleEmojiPopup(message)} className="hidden group-hover:inline-block ml-3 mt-2 relative group-hover:rounded-full group-hover:bg-[#BDBBB6] group-hover:h-6 group-hover:w-6 group-hover:flex group-hover:justify-center group-hover:items-center">
                                      <span aria-hidden="true" data-icon="react" className="text-white"><svg viewBox="0 0 15 15" width="15" preserveAspectRatio="xMidYMid meet" fill="none"><title>react</title><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 11.6305 3.36946 15 7.5 15C11.6527 15 15 11.6305 15 7.5C15 3.36946 11.6305 0 7.5 0C3.36946 0 0 3.36946 0 7.5ZM10.995 8.69333C11.1128 8.67863 11.2219 8.66503 11.3211 8.65309C11.61 8.63028 11.8076 8.91918 11.6784 9.13965C10.8573 10.6374 9.29116 11.793 7.50455 11.793C5.71794 11.793 4.15181 10.6602 3.33072 9.16246C3.18628 8.91918 3.37634 8.63028 3.66524 8.65309C3.79123 8.66749 3.93521 8.68511 4.09426 8.70457C4.94292 8.80842 6.22074 8.96479 7.48174 8.96479C8.81855 8.96479 10.1378 8.80025 10.995 8.69333ZM5.41405 7.37207C6.05761 7.37207 6.60923 6.72851 6.60923 6.02978C6.60923 5.30348 6.05761 4.6875 5.41405 4.6875C4.77048 4.6875 4.21886 5.33106 4.21886 6.02978C4.20967 6.75609 4.77048 7.37207 5.41405 7.37207ZM10.7807 6.05619C10.7807 6.74114 10.24 7.37201 9.60912 7.37201C8.97825 7.37201 8.4375 6.76818 8.4375 6.05619C8.4375 5.37124 8.97825 4.74037 9.60912 4.74037C10.24 4.74037 10.7807 5.34421 10.7807 6.05619Z" fill="currentColor"></path></svg></span>
                                    </div>
                                    {selectedMessageId === message.id && toggleReaction && (
                                      <div className="absolute top-[-3rem] left-[3rem] z-10 flex space-x-1 bg-white shadow-lg border border-gray-200 p-2 rounded-md">
                                        {["👍", "❤️", "😂", "😮", "😢", "👏"].map((emoji, index) => (
                                          <button onClick={() => {
                                            // console.log("Selected emoji:", emoji); // Log emoji to see if it’s defined
                                            giveReaction(message, emoji); // Call giveReaction with message and emoji
                                          }}
                                          key={index} className="text-xl hover:bg-gray-200 p-1 rounded-full">
                                            {emoji}
                                          </button>
                                        ))}
                                      </div>
                                    )}
                                    </div>
                                  )
                                }
                              </div>
                          </>
                        ))
                    ) : (
                      <>
                      {
                        Array.isArray(chats) && chats.length > 0 ? 
                        (
                          chats
                          ?.slice()
                          // ?.reverse()
                          ?.map((message) => (
                            <>
                                <div
                                  key={message.id}
                                  className={`flex items-center group relative ${
                                    message?.data?.fromMe ? "justify-end" : "justify-start"
                                  }`}
                                >
                                  {message?.data?.fromMe ? 
                                    (<div className="relative">
                                    <div onClick={()=> toggleEmojiPopup(message)} className="hidden group-hover:inline-block mr-3 mt-2 relative group-hover:rounded-full group-hover:bg-[#BDBBB6] group-hover:h-6 group-hover:w-6 group-hover:flex group-hover:justify-center group-hover:items-center">
                                      <span aria-hidden="true" data-icon="react" className="text-white"><svg viewBox="0 0 15 15" width="15" preserveAspectRatio="xMidYMid meet" fill="none"><title>react</title><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 11.6305 3.36946 15 7.5 15C11.6527 15 15 11.6305 15 7.5C15 3.36946 11.6305 0 7.5 0C3.36946 0 0 3.36946 0 7.5ZM10.995 8.69333C11.1128 8.67863 11.2219 8.66503 11.3211 8.65309C11.61 8.63028 11.8076 8.91918 11.6784 9.13965C10.8573 10.6374 9.29116 11.793 7.50455 11.793C5.71794 11.793 4.15181 10.6602 3.33072 9.16246C3.18628 8.91918 3.37634 8.63028 3.66524 8.65309C3.79123 8.66749 3.93521 8.68511 4.09426 8.70457C4.94292 8.80842 6.22074 8.96479 7.48174 8.96479C8.81855 8.96479 10.1378 8.80025 10.995 8.69333ZM5.41405 7.37207C6.05761 7.37207 6.60923 6.72851 6.60923 6.02978C6.60923 5.30348 6.05761 4.6875 5.41405 4.6875C4.77048 4.6875 4.21886 5.33106 4.21886 6.02978C4.20967 6.75609 4.77048 7.37207 5.41405 7.37207ZM10.7807 6.05619C10.7807 6.74114 10.24 7.37201 9.60912 7.37201C8.97825 7.37201 8.4375 6.76818 8.4375 6.05619C8.4375 5.37124 8.97825 4.74037 9.60912 4.74037C10.24 4.74037 10.7807 5.34421 10.7807 6.05619Z" fill="currentColor"></path></svg></span>
                                    </div>
                                    {selectedMessageId === message.id && toggleReaction && (
                                      <div className="absolute top-[-3rem] right-[2rem] z-10 flex space-x-1 bg-white shadow-lg border border-gray-200 p-2 rounded-md">
                                        {["👍", "❤️", "😂", "😮", "😢", "👏"].map((emoji, index) => (
                                          <button onClick={() => {
                                            // console.log("Selected emoji:", emoji); // Log emoji to see if it’s defined
                                            giveReaction(message, emoji); // Call giveReaction with message and emoji
                                          }}
                                          key={index} className="text-xl hover:bg-gray-200 p-1 rounded-full">
                                            {emoji}
                                          </button>
                                        ))}
                                      </div>
                                    )}
                                    </div>
                                    ) : (null)
                                  }
                                  
                                  <div
                                    className={`p-2 flex justify-between rounded-lg flex-col max-w-[80%] break-words relative ${
                                      message?.data?.fromMe
                                        ? "bg-[#D9FDD2] dark:bg-[#015C4B] dark:text-white text-black"
                                        : "bg-white dark:bg-[#1F2C33] dark:text-white text-gray-800"
                                    }`}
                                    style={{
                                      minWidth: "220px",
                                      maxWidth: "60%",
                                      wordBreak: "break-word",
                                      padding: "8px 12px",
                                      borderRadius: "10px",
                                      // minHeight: "70px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    
                                    <div className="flex flex-col pt-[0.5rem]">
                                     {renderMessageContent(message?.data)}
                                    <span className="text-xs dark:text-[#A3BEB7] text-gray-500 block absolute right-[5px] bottom-[5px] flex items-center">
                                      {getLastMessageTime(message?.data?.timestamp)}
                                      {renderMessageTicks(message?.data)}
                                    </span>
                                    </div>
                                    
                                    {Array.isArray(message?.data?._data?.reactions) &&
                                      renderReactions(message?.data?._data?.reactions)
                                    }
                                  </div>

                                  {message?.data?.fromMe ? 
                                    (null) : (
                                      <div className="relative">
                                      <div onClick={()=> toggleEmojiPopup(message)} className="hidden group-hover:inline-block ml-3 mt-2 relative group-hover:rounded-full group-hover:bg-[#BDBBB6] group-hover:h-6 group-hover:w-6 group-hover:flex group-hover:justify-center group-hover:items-center">
                                        <span aria-hidden="true" data-icon="react" className="text-white"><svg viewBox="0 0 15 15" width="15" preserveAspectRatio="xMidYMid meet" fill={`${darkMode} ? 'white : 'black`}><title>react</title><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5C0 11.6305 3.36946 15 7.5 15C11.6527 15 15 11.6305 15 7.5C15 3.36946 11.6305 0 7.5 0C3.36946 0 0 3.36946 0 7.5ZM10.995 8.69333C11.1128 8.67863 11.2219 8.66503 11.3211 8.65309C11.61 8.63028 11.8076 8.91918 11.6784 9.13965C10.8573 10.6374 9.29116 11.793 7.50455 11.793C5.71794 11.793 4.15181 10.6602 3.33072 9.16246C3.18628 8.91918 3.37634 8.63028 3.66524 8.65309C3.79123 8.66749 3.93521 8.68511 4.09426 8.70457C4.94292 8.80842 6.22074 8.96479 7.48174 8.96479C8.81855 8.96479 10.1378 8.80025 10.995 8.69333ZM5.41405 7.37207C6.05761 7.37207 6.60923 6.72851 6.60923 6.02978C6.60923 5.30348 6.05761 4.6875 5.41405 4.6875C4.77048 4.6875 4.21886 5.33106 4.21886 6.02978C4.20967 6.75609 4.77048 7.37207 5.41405 7.37207ZM10.7807 6.05619C10.7807 6.74114 10.24 7.37201 9.60912 7.37201C8.97825 7.37201 8.4375 6.76818 8.4375 6.05619C8.4375 5.37124 8.97825 4.74037 9.60912 4.74037C10.24 4.74037 10.7807 5.34421 10.7807 6.05619Z" fill="currentColor"></path></svg></span>
                                      </div>
                                      {selectedMessageId === message.id && toggleReaction && (
                                        <div className="absolute top-[-3rem] left-[3rem] z-10 flex space-x-1 bg-white shadow-lg border border-gray-200 p-2 rounded-md">
                                          {["👍", "❤️", "😂", "😮", "😢", "👏"].map((emoji, index) => (
                                            <button onClick={() => {
                                              // console.log("Selected emoji:", emoji); // Log emoji to see if it’s defined
                                              giveReaction(message, emoji); // Call giveReaction with message and emoji
                                            }}
                                            key={index} className="text-xl hover:bg-gray-200 p-1 rounded-full">
                                              {emoji}
                                            </button>
                                          ))}
                                        </div>
                                      )}
                                      </div>
                                    )
                                  }
                                </div>
                            </>
                          ))
                        ) 
                        : 
                        (
                          null
                        ) 
                      }
                      </>
                    )}
                    {scroll && <button onClick={scrollToBottom} className="transform rotate-90 fixed bottom-[10rem] right-[3rem] bg-white p-1 rounded-full shadow-lg transition-all">
                      <span aria-hidden="true" data-icon="chevron" class="x1h7gb8d x1rg5ohu x1iffjtl"><svg viewBox="0 0 30 30" height="30" width="30" preserveAspectRatio="xMidYMid meet" class="" x="0px" y="0px"><title>chevron</title><path fill="#888D90" d="M11,21.212L17.35,15L11,8.65l1.932-1.932L21.215,15l-8.282,8.282L11,21.212z"></path></svg></span>
                    </button>}
                    <div ref={chatEndRef}></div>
                  </div>
                </div>
                <SentMessage
                  number={number}
                  selectedChat={selectedChat}
                  chats={chats}
                  setChats={setChats}
                  setSend={setSend}
                />
              </>
            )
          ) : (
            <div className="flex-1 flex-col gap-5 flex items-center justify-center bg-[#f8f9fa] dark:bg-[#212E35]">
              {/* <button
                onClick={openModal}
                className="bg-blue-500 text-white p-2 rounded-md"
              >
                Add WhatsApp Number
              </button> */}
              <p className="text-gray-500 dark:text-gray-400 text-lg">
                Select a chat to start messaging
              </p>

              {/* Modal */}
              {isModalOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white p-6 rounded-md shadow-lg">
                    <h2 className="text-xl mb-4">Enter WhatsApp Number</h2>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="tel"
                        placeholder="Enter phone number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        className="border p-2 mb-4 w-full rounded-md"
                        required
                      />
                      <div className="flex justify-end space-x-2">
                        <button
                          type="button"
                          onClick={closeModal}
                          className="bg-gray-400 text-white p-2 rounded-md"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="bg-blue-500 text-white p-2 rounded-md"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) 
      : 
      (
        <div className="flex-1 flex-col gap-5 flex items-center justify-center bg-[#f8f9fa] dark:bg-[#212E35]">
          {
            selectedChat ? 
            (
              <p className="text-gray-500 dark:text-gray-400 text-lg flex items-center gap-2">
                Loading Chats
                <ClipLoader />
                {/* <EazybeLoader /> */}
              </p>
            ) 
            : 
            (
              <p className="text-gray-500 dark:text-gray-400 text-lg">
                Select a chat to start messaging
              </p>
            )
          }
        </div>
      )
    }
    {
      groupIndividualProfile && (
        <GroupIndividualUI setSelectedChat={setSelectedChat} loading={loading} setGroupIndividualProfile={setGroupIndividualProfile} getChatter={getChatter} />
      )
    }
    {isPopupOpen && (
        <WhatsAppImagePopup isOpen={isOpen} setIsOpen={setIsOpen} imageUrl={popupImageUrl} alt="Sample landscape" />
    )}
    </>
    
  );
});

export default MainChatArea;
