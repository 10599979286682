import React from "react";

const FilePreview = ({ fileName, fileType, fileSize, downloadUrl }) => {
    // console.log('filePreview', {fileName, fileType, fileSize, downloadUrl});
    
  const getFileIcon = (type) => {
    // Return an appropriate icon or placeholder based on the file type
    if (type.includes("csv")) return "📄"; // CSV icon placeholder
    if (type.includes("doc") || type.includes("word")) return "📃"; // DOC icon placeholder
    return "📁"; // Default icon for other files
  };

  return (
    <div className="flex flex-col mb-4">
      <div className="flex items-center bg-[#dcf8c6] rounded-lg p-4 shadow-md gap-[1rem]">
        {/* File Icon */}
        <div className="text-4xl mr-4">{getFileIcon(fileType)}</div>

        {/* File Details */}
        <div className="flex-1">
          <p className="text-base font-bold text-gray-800">{fileName}</p>
        </div>

        {/* Download Button */}
        <a
          href={downloadUrl}
          className="text-green-500 hover:text-green-600 text-xl"
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          ⬇️
        </a>
      </div>
    </div>
  );
};

export default FilePreview;
