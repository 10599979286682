import { create } from "zustand";
import { fetchChatOfChatterOrGroup, fetchWhatsappChatter, fetchWhatsappContacts, getProfilePicture, sessionActiveorNot } from "../zustand/cloud/cloud.action";

const chatterStore = create((set)=> ({
    //initialization
    data: null,
    profilePicture: {},
    contacts: {},
    loading: false,
    error: null,

    //actions
    fetchChatters: async () => {
        set({ loading: true, error: null });
        try {
            const response = await fetchWhatsappChatter();
            console.log('fetchChatters', response);
            
            set({ data: response, loading: false });
            return response; // Return the response
        } catch (error) {
            set({ error: error?.message, loading: false });
            throw error; // Rethrow the error to be handled in the calling function
        }
    },
    
    fetchContacts: async () => {
        set({ loading: true, error: null });
        try {

            const response = await fetchWhatsappContacts();

            if(response?.statusCode === 404){
                set({error: response, loading: false});
                return;
            }
            
            const contacts = response?.reduce((acc, contact) => {
                if(contact.id.includes("@c.us")){
                    contact.id = contact.id.replace("@c.us", "@s.whatsapp.net");
                }
                acc[contact.id] = {
                    name: contact?.name,
                    pushname: contact?.pushname
                };
                return acc;
            }, {});
    
            // Update the Zustand store with the transformed contacts
            set({ contacts, loading: false });
            
        } catch (error) {
            set({ error: error?.message, loading: false });
            throw error; // Rethrow the error to be handled in the calling function
        }
    },

    fetchChats: async(sessionName, chatId) => {
        set({loading: true, error: null});
        try {
            const response = await fetchChatOfChatterOrGroup(sessionName, chatId);
            console.log('fetchChats', response);
            
            set({data: response.data, loading: false})
        } catch (error) {
            set({error: error?.message, loading: false})
        }
    },

    sessionActiveValidation: async(sessionName, chatId) => {
        set({loading: true, error: null});
        try {
            const response = await sessionActiveorNot(sessionName);
            console.log('sessionActiveValidation', response);
            
            set({data: response.data, loading: false})
        } catch (error) {
            set({error: error?.message, loading: false})
        }
    },

    getProfilepictureStore: async(sessionName, session, chatId) => {
        set({loading: true, error: null});
        try {
            console.log("FIRST TIME", sessionName, session,chatId)
            if (!session || !sessionName || !chatId || chatId.includes("newsletter")) return;
            
            const response = await getProfilePicture(sessionName, session, chatId);
            console.log('sessionActiveValidation', response);
            
            set(state => ({
                profilePicture: {
                ...state.profilePicture,
                [chatId]: response?.profilePictureURL
            }, 
            loading: false
        }))
        } catch (error) {
            set({error: error?.message, loading: false})
        }
    }

}));

export default chatterStore;