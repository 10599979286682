import { User } from "lucide-react";
import React, { useCallback, useRef } from "react";
import { addChatterToLocalStorage, doesNumberExistInLocalStorage, getLastMessageTime, truncateMessage } from "../../../utils/helper";
import { fetchChatOfChatterOrGroup } from "../../../zustand/cloud/cloud.action";
import { addData, getChatsByToProperty } from "../../../db/indexedDB";
import chatterStore from "../../../store/chatterStore";

const Chatter = React.memo(({ selectedChat, setSelectedChat, chat, chatter, setChatter, setGroupIndividualProfile, setChats, chats, db, profileImage }) => {
  // console.log('chatssss', {selectedChat, setSelectedChat, chat, chatter, setChatter, setGroupIndividualProfile, setChats, chats, db, profileImage});
  
  const { contacts, profilePicture } = chatterStore();

  const debounceTimeout = useRef(null);
  const handleSelectedChat = useCallback(async (chat, key) => {
    // Clear the previous timeout to debounce the calls
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new timeout to process the latest click after 400ms
    debounceTimeout.current = setTimeout(async () => {
      try {
        const chatId = chat?.id;

        // Avoid redundant selection (if already selected, do nothing)
        if (selectedChat?.split("@")[0] === chatId?.split("@")[0]) {
          return;
        }

        // console.log('handleSelectedChat', chat, key);
        chat.unreadCount = 0;
        addData(db, "chatter", chat);
        setSelectedChat(chatId);
        setGroupIndividualProfile(false);

        const number = localStorage.getItem("whatsappNumber");
        const isGroup = key === "G";
        const adjustedChatId = chatId // isGroup ? chatId : chatId?.replace("@s.whatsapp.net", "@c.us");

        const chatterIndex = chatter.findIndex(c => c?.id === adjustedChatId);
        // console.log('Chatter Index', { chatterIndex, chatter });

        // Fetch local chats
        const checkId = isGroup ? chatId : chatId?.replace("@s.whatsapp.net", "@c.us");
        const localChats = await getChatsByToProperty(db, "chats", checkId);
        // console.log("Local chats fetched:", localChats);
        setChats(localChats.sort((a, b) => b?.data?.timestamp - a?.data?.timestamp));

        const lastTimeSync = localChats?.[localChats.length - 1]?.data?.timestamp;
        // console.log("Last time sync:", lastTimeSync);

        // Check if chat is already stored locally
        if (!doesNumberExistInLocalStorage(checkId)) {
          // console.log("Fetching remote chats for:", checkId);
          const remoteChats = await fetchChatOfChatterOrGroup(number, adjustedChatId);

          if (remoteChats && Array.isArray(remoteChats)) {
            // Add remote chats to IndexedDB
            await Promise.all(
              remoteChats.map(chats => {
                const chatterWithId = { id: chats.id, data: chats };
                return addData(db, "chats", chatterWithId);
              })
            );

            // console.log("Remote chats fetched:", remoteChats);

            // Transform and merge chats
            const transformedArray = remoteChats.map(chats => ({ id: chats.id, data: chats }));
            const mergedChats = [...localChats, ...transformedArray].reduce((acc, chat) => {
              const exists = acc.find(c => c.data.id === chat.id);
              return exists ? acc : [...acc, chat];
            }, []);

            // Sort chats by timestamp, in descending order
            // mergedChats.sort((a, b) => b?.data?.timestamp - a?.data?.timestamp)?.reverse();
            mergedChats.sort((a, b) => (a?.data?.timestamp || 0) - (b?.data?.timestamp || 0));

            // console.log("Merged chats:", mergedChats);

            setChats(mergedChats);
            addChatterToLocalStorage(adjustedChatId);
          } else {
            console.warn("No remote chats found for this ID:", adjustedChatId);
          }
        }
      } catch (error) {
        console.error("Error handling selected chat:", error);
      }
    }, 500); // Debounce delay (400ms)
  }, [selectedChat, chatter, db]);

  // console.log('chaterrr', {contacts, chat});
  

  let contactNumber = chat?.id?.split("@")[0];
  // console.log('contactNumber', contactNumber);

  if (chat?.id === "status@broadcast") {
    return;
  }

  const addTicksToLastMessage = (message) => {
    // console.log('addTicksToLastMessage', message);
    
    if(message?.lastMessage?.ack === 3){
      return (
        <div className="flex gap-1 items-center">
          <span
            aria-hidden="false"
            aria-label=" Read "
            data-icon="msg-dblcheck"
            class="x1q15gih"
          >
            <svg
              viewBox="0 0 16 11"
              height="11"
              width="16"
              preserveAspectRatio="xMidYMid meet"
              class=""
              fill="none"
            >
              <title>msg-dblcheck</title>
              <path
                d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                fill="#53BDEA"
              ></path>
            </svg>
          </span>
          <p>{truncateMessage(message, 'J')}</p>
        </div>
      );
    }
    if(message?.lastMessage?.ack === 2){
      return (
        <div className="flex gap-1 items-center">
          <span aria-hidden="true" data-icon="status-dblcheck">
            <svg
              viewBox="0 0 18 18"
              height="18"
              width="18"
              preserveAspectRatio="xMidYMid meet"
              class=""
              version="1.1"
              x="0px"
              y="0px"
              enable-background="new 0 0 18 18"
            >
              <title>status-dblcheck</title>
              <path
                fill="#667681"
                d="M17.394,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-0.427-0.388c-0.171-0.167-0.431-0.15-0.578,0.038L7.792,13.13 c-0.147,0.188-0.128,0.478,0.043,0.645l1.575,1.51c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C17.616,5.456,17.582,5.182,17.394,5.035z M12.502,5.035l-0.57-0.444c-0.188-0.147-0.462-0.113-0.609,0.076l-6.39,8.198 c-0.147,0.188-0.406,0.206-0.577,0.039l-2.614-2.556c-0.171-0.167-0.447-0.164-0.614,0.007l-0.505,0.516 c-0.167,0.171-0.164,0.447,0.007,0.614l3.887,3.8c0.171,0.167,0.43,0.149,0.577-0.039l7.483-9.602 C12.724,5.456,12.69,5.182,12.502,5.035z"
              ></path>
            </svg>
          </span>
          <p>{truncateMessage(message, 'J')}</p>
        </div>
      );
    }
    else{
      return (
        <div className="flex gap-1 items-center">
          <span
            aria-hidden="false"
            aria-label=" Sent "
            data-icon="msg-check"
            class=""
          >
            <svg
              viewBox="0 0 12 11"
              height="11"
              width="16"
              preserveAspectRatio="xMidYMid meet"
              class=""
              fill="none"
            >
              <title>msg-check</title>
              <path
                d="M11.1549 0.652832C11.0745 0.585124 10.9729 0.55127 10.8502 0.55127C10.7021 0.55127 10.5751 0.610514 10.4693 0.729004L4.28038 8.36523L1.87461 6.09277C1.8323 6.04622 1.78151 6.01025 1.72227 5.98486C1.66303 5.95947 1.60166 5.94678 1.53819 5.94678C1.407 5.94678 1.29275 5.99544 1.19541 6.09277L0.884379 6.40381C0.79128 6.49268 0.744731 6.60482 0.744731 6.74023C0.744731 6.87565 0.79128 6.98991 0.884379 7.08301L3.88047 10.0791C4.02859 10.2145 4.19574 10.2822 4.38194 10.2822C4.48773 10.2822 4.58929 10.259 4.68663 10.2124C4.78396 10.1659 4.86436 10.1003 4.92784 10.0156L11.5738 1.59863C11.6458 1.5013 11.6817 1.40186 11.6817 1.30029C11.6817 1.14372 11.6183 1.01888 11.4913 0.925781L11.1549 0.652832Z"
                fill="#667681"
              ></path>
            </svg>
          </span>
          <p>{truncateMessage(message, 'J')}</p>
        </div>
      );
    }
  };

  function updateChatterWithNewMessage(chatter, chat) {
    if (!chatter || !chat) return;
  
    // Update lastMessage and timestamp using newMessage
    const updatedChatter = {
      ...chatter,
      unreadCount: 0
    };

    return updatedChatter;
  }

  return (
    <>
      {chat?.id?.includes("g.us") ? (
        <div
          key={chat?.id}
          className={`p-4 hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-700 ${
            selectedChat === chat?.id
              ? "bg-[#EFF2F5] dark:bg-[#2A3942]"
              : ""
          }`}
          onClick={() => handleSelectedChat(chat, "G")}
        >
          <div className="flex items-center relative">
            {
              profilePicture[chat?.id] ? (
                <img height={"50px"} width={"50px"} className="rounded-full" src={profilePicture[chat?.id]} />
              )
              :
              (
                <span
                  aria-hidden="true"
                  data-icon="default-user"
                  class=""
                >
                  <svg
                    viewBox="0 0 212 212"
                    height="50"
                    width="50"
                    preserveAspectRatio="xMidYMid meet"
                    class="xh8yej3 x5yr21d"
                    version="1.1"
                    x="0px"
                    y="0px"
                    enable-background="new 0 0 212 212"
                  >
                    <title>default-user</title>
                    <path
                      fill="#DFE5E7"
                      class="background"
                      d="M106.251,0.5C164.653,0.5,212,47.846,212,106.25S164.653,212,106.25,212C47.846,212,0.5,164.654,0.5,106.25 S47.846,0.5,106.251,0.5z"
                    ></path>
                    <g>
                      <path
                        fill="#FFFFFF"
                        class="primary"
                        d="M173.561,171.615c-0.601-0.915-1.287-1.907-2.065-2.955c-0.777-1.049-1.645-2.155-2.608-3.299 c-0.964-1.144-2.024-2.326-3.184-3.527c-1.741-1.802-3.71-3.646-5.924-5.47c-2.952-2.431-6.339-4.824-10.204-7.026 c-1.877-1.07-3.873-2.092-5.98-3.055c-0.062-0.028-0.118-0.059-0.18-0.087c-9.792-4.44-22.106-7.529-37.416-7.529 s-27.624,3.089-37.416,7.529c-0.338,0.153-0.653,0.318-0.985,0.474c-1.431,0.674-2.806,1.376-4.128,2.101 c-0.716,0.393-1.417,0.792-2.101,1.197c-3.421,2.027-6.475,4.191-9.15,6.395c-2.213,1.823-4.182,3.668-5.924,5.47 c-1.161,1.201-2.22,2.384-3.184,3.527c-0.964,1.144-1.832,2.25-2.609,3.299c-0.778,1.049-1.464,2.04-2.065,2.955 c-0.557,0.848-1.033,1.622-1.447,2.324c-0.033,0.056-0.073,0.119-0.104,0.174c-0.435,0.744-0.79,1.392-1.07,1.926 c-0.559,1.068-0.818,1.678-0.818,1.678v0.398c18.285,17.927,43.322,28.985,70.945,28.985c27.678,0,52.761-11.103,71.055-29.095 v-0.289c0,0-0.619-1.45-1.992-3.778C174.594,173.238,174.117,172.463,173.561,171.615z"
                      ></path>
                      <path
                        fill="#FFFFFF"
                        class="primary"
                        d="M106.002,125.5c2.645,0,5.212-0.253,7.68-0.737c1.234-0.242,2.443-0.542,3.624-0.896 c1.772-0.532,3.482-1.188,5.12-1.958c2.184-1.027,4.242-2.258,6.15-3.67c2.863-2.119,5.39-4.646,7.509-7.509 c0.706-0.954,1.367-1.945,1.98-2.971c0.919-1.539,1.729-3.155,2.422-4.84c0.462-1.123,0.872-2.277,1.226-3.458 c0.177-0.591,0.341-1.188,0.49-1.792c0.299-1.208,0.542-2.443,0.725-3.701c0.275-1.887,0.417-3.827,0.417-5.811 c0-1.984-0.142-3.925-0.417-5.811c-0.184-1.258-0.426-2.493-0.725-3.701c-0.15-0.604-0.313-1.202-0.49-1.793 c-0.354-1.181-0.764-2.335-1.226-3.458c-0.693-1.685-1.504-3.301-2.422-4.84c-0.613-1.026-1.274-2.017-1.98-2.971 c-2.119-2.863-4.646-5.39-7.509-7.509c-1.909-1.412-3.966-2.643-6.15-3.67c-1.638-0.77-3.348-1.426-5.12-1.958 c-1.181-0.355-2.39-0.655-3.624-0.896c-2.468-0.484-5.035-0.737-7.68-0.737c-21.162,0-37.345,16.183-37.345,37.345 C68.657,109.317,84.84,125.5,106.002,125.5z"
                      ></path>
                    </g>
                  </svg>
                </span>
              )
            }
            <div className="flex-1 min-w-0 ml-4 flex flex-col gap-[0.2rem]">
              <p className={`${chat?.unreadMentionCount > 0 ? 'text-[#3B4A54] font-bold' : 'text-[#111B21]'} dark:text-gray-200`}>
                {/* Name of contact */}
                {chat?.name?.length > 40 
    ? `${chat?.name.substring(0, 35)}...` 
    : chat?.name || contacts?.[chat?.id]?.name || contacts?.[chat?.id]?.pushname || chat?.id?.split("@")[0]}
              </p>
              <div className="flex items-center gap-1">
                <p className={`text-sm  ${chat?.unreadMentionCount > 0 ? 'text-[#3B4A54] font-medium' : 'text-[#667681]'} dark:text-gray-400`}>
                  ~{chat?.lastMessage?.fromMe ? "You: " : chat?.lastMessage?._data?.participant?.split('@')[0] || chat?.lastMessage?._data?.key?.participant?.split('@')[0]}:
                </p>
                <p className={`${chat?.unreadCount > 0 ?  'text-[#3B4A54] font-medium' : 'text-[#667681]'} text-sm dark:text-gray-400`}>
                  {/* Last message */}
                  {/* {chat?.lastMessage?.fromMe
                    ? addTicksToLastMessage(chat)
                    : truncateMessage(chat, 'G')} */}
                    {truncateMessage(chat, 'G')}
                </p>
              </div>
            </div>
            <span className={`${chat.lastMessage?.ackName === "UNKNOWN" || chat.unreadCount > 0 ? 'text-[#23D366] font-medium' : 'text-[#6B7B85]' } text-xs dark:text-gray-400 ml-2 flex-shrink-0 absolute top-1 right-0`}>
              {/* LastTime */}
              {getLastMessageTime(chat?.conversationTimestamp || chat?.timestamp)}
            </span>
            {/* {chat.lastMessage?.ackName === "UNKNOWN" || chat.unreadCount > 0 ? */}
              {/* <span className="rounded-full bg-[#23D366] font-medium flex items-center justify-center text-xs text-white h-5 w-5 absolute top-[1.8rem] right-0"> */}
                {/* {chat.unreadCount} */}
              {/* </span> */}
              
              {/* : */}
              {/* null */}
            {/* } */}
          </div>
        </div>
      ) : (
        <div
          key={chat?.id}
          className={`p-4 hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-700 ${
            selectedChat?.replace("@c.us", "@s.whatsapp.net") === chat?.id
              ? "bg-[#EFF2F5] dark:bg-[#2A3942]"
              : ""
          }`}
          onClick={() => handleSelectedChat(chat, "C")}
        >
          <div className="flex items-center relative">
          {
              profilePicture[chat?.id] ? (
                <img height={"50px"} width={"50px"} className="rounded-full" src={profilePicture[chat?.id]} />
              )
              :
              (
                <span
                  aria-hidden="true"
                  data-icon="default-user"
                  class=""
                >
                  <svg
                    viewBox="0 0 212 212"
                    height="50"
                    width="50"
                    preserveAspectRatio="xMidYMid meet"
                    class="xh8yej3 x5yr21d"
                    version="1.1"
                    x="0px"
                    y="0px"
                    enable-background="new 0 0 212 212"
                  >
                    <title>default-user</title>
                    <path
                      fill="#DFE5E7"
                      class="background"
                      d="M106.251,0.5C164.653,0.5,212,47.846,212,106.25S164.653,212,106.25,212C47.846,212,0.5,164.654,0.5,106.25 S47.846,0.5,106.251,0.5z"
                    ></path>
                    <g>
                      <path
                        fill="#FFFFFF"
                        class="primary"
                        d="M173.561,171.615c-0.601-0.915-1.287-1.907-2.065-2.955c-0.777-1.049-1.645-2.155-2.608-3.299 c-0.964-1.144-2.024-2.326-3.184-3.527c-1.741-1.802-3.71-3.646-5.924-5.47c-2.952-2.431-6.339-4.824-10.204-7.026 c-1.877-1.07-3.873-2.092-5.98-3.055c-0.062-0.028-0.118-0.059-0.18-0.087c-9.792-4.44-22.106-7.529-37.416-7.529 s-27.624,3.089-37.416,7.529c-0.338,0.153-0.653,0.318-0.985,0.474c-1.431,0.674-2.806,1.376-4.128,2.101 c-0.716,0.393-1.417,0.792-2.101,1.197c-3.421,2.027-6.475,4.191-9.15,6.395c-2.213,1.823-4.182,3.668-5.924,5.47 c-1.161,1.201-2.22,2.384-3.184,3.527c-0.964,1.144-1.832,2.25-2.609,3.299c-0.778,1.049-1.464,2.04-2.065,2.955 c-0.557,0.848-1.033,1.622-1.447,2.324c-0.033,0.056-0.073,0.119-0.104,0.174c-0.435,0.744-0.79,1.392-1.07,1.926 c-0.559,1.068-0.818,1.678-0.818,1.678v0.398c18.285,17.927,43.322,28.985,70.945,28.985c27.678,0,52.761-11.103,71.055-29.095 v-0.289c0,0-0.619-1.45-1.992-3.778C174.594,173.238,174.117,172.463,173.561,171.615z"
                      ></path>
                      <path
                        fill="#FFFFFF"
                        class="primary"
                        d="M106.002,125.5c2.645,0,5.212-0.253,7.68-0.737c1.234-0.242,2.443-0.542,3.624-0.896 c1.772-0.532,3.482-1.188,5.12-1.958c2.184-1.027,4.242-2.258,6.15-3.67c2.863-2.119,5.39-4.646,7.509-7.509 c0.706-0.954,1.367-1.945,1.98-2.971c0.919-1.539,1.729-3.155,2.422-4.84c0.462-1.123,0.872-2.277,1.226-3.458 c0.177-0.591,0.341-1.188,0.49-1.792c0.299-1.208,0.542-2.443,0.725-3.701c0.275-1.887,0.417-3.827,0.417-5.811 c0-1.984-0.142-3.925-0.417-5.811c-0.184-1.258-0.426-2.493-0.725-3.701c-0.15-0.604-0.313-1.202-0.49-1.793 c-0.354-1.181-0.764-2.335-1.226-3.458c-0.693-1.685-1.504-3.301-2.422-4.84c-0.613-1.026-1.274-2.017-1.98-2.971 c-2.119-2.863-4.646-5.39-7.509-7.509c-1.909-1.412-3.966-2.643-6.15-3.67c-1.638-0.77-3.348-1.426-5.12-1.958 c-1.181-0.355-2.39-0.655-3.624-0.896c-2.468-0.484-5.035-0.737-7.68-0.737c-21.162,0-37.345,16.183-37.345,37.345 C68.657,109.317,84.84,125.5,106.002,125.5z"
                      ></path>
                    </g>
                  </svg>
                </span>
              )
            }
            <div className="flex-1 min-w-0 ml-4">
              <p className="text-[#111B21] dark:text-gray-200 truncate">
                {/* Name of contact */}
                {/* {chat?.name?.length > 40 ? `${chat?.name.substring(0, 40)}...`: chat?.name|| contactNumber} */}
                {chat?.name?.length > 40 ? `${chat?.name.substring(0, 35)}...` : chat?.name || contacts?.[chat?.id]?.name || contacts?.[chat?.id]?.pushname || chat?.id?.split('@')[0]}
              </p>
              <p className="text-sm text-[#667681] dark:text-gray-400 truncate">
                {/* Last message */}
                {chat?.lastMessage?.fromMe
                  ? addTicksToLastMessage(chat)
                  : truncateMessage(chat, 'C')}
              </p>
            </div>
            <span className="text-xs text-gray-500 dark:text-gray-400 ml-2 flex-shrink-0 absolute top-1 right-0">
              {/* LastTime */}
              {getLastMessageTime(chat?.conversationTimestamp || chat?.timestamp)}
            </span>

            {/* {chat.lastMessage?.ackName === "UNKNOWN" || chat.unreadCount > 0 ? */}
              {/* <span className="rounded-full bg-[#23D366] font-medium flex items-center justify-center text-xs text-white h-5 w-5 absolute top-[1.8rem] right-0"> */}
                {/* {chat.unreadCount} */}
              {/* </span> */}
              
              {/* : */}
              {/* null */}
            {/* } */}
          </div>
        </div>
      )}
    </>
  );
});

export default Chatter;
