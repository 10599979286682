import './App.css';
import WhatsApp from './pages/whatsAppUI/WhatsApp';
import i18n from './i18n.js';
import { useEffect, useState } from 'react';
import SignUpPage from './pages/sign-up/Signup.js';
import useStorage from './services /store/useSignupStore.js';
import { Navigate, Route, Router, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSignUpStore from './services /store/useSignupStore.js';
import ProtectedRoute from './ProtectedRoute.js';
import { openDB } from './db/indexedDB.js';

function App() {
  const languagesPresent = ['en', 'es', 'hi', 'id', 'pt', 'pt_br', 'pt_pt'];
  useEffect(() => {
    let preferredLanguage = localStorage.getItem("preferredLanguage");
    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage);
      // i18n.changeLanguage("en");
    } else {
      let language = navigator.language.slice(0, 2);
      // const languagesPresent = ['en', 'es', 'hi', 'id', 'pt']
      if (languagesPresent.includes(language)) {
        i18n.changeLanguage(language);
        // i18n.changeLanguage("en");
      } else {
        i18n.changeLanguage("en");
      }
    }
  }, []);

  const [db, setDb] = useState(null);
  useEffect(() => {
    (async () => {
      // console.log('starting db making 1');
      
      const initializeDB = async () => {
        const dbName = 'whatsappDB';
        const dbInstance = await openDB(dbName, 2);
        setDb(dbInstance);
      };
  
      await initializeDB(); // Await the completion of initializeDB
    })();
  }, []);

  const navigate = useNavigate();
  const isUserLoggedIn = useSignUpStore((state) => state.isUserLoggedIn);

  const [userLogin, setUserLogin] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("loggedIn");
    if (token) {
      setUserLogin(true);
      navigate("/whatsapp");
    } else {
      setUserLogin(false);
      navigate("/signup");
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/whatsapp" element={
          <ProtectedRoute>
            <WhatsApp db={db} setDb={setDb} />
          </ProtectedRoute>
        } 
        />
        <Route path="/" element={userLogin ? <Navigate to="/whatsapp" /> : <Navigate to="/signup" />} /> 
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
