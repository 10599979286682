import React, { useCallback, useEffect, useRef, useState } from "react";
import MainChatArea from "../../components/UI/MainChatArea/MainChatArea";
import ChatterSidebar from "../../components/UI/SideBar/ChatterSidebar";
import * as Pusher from "pusher-js";
import { addData, clearIndexedDB, getAllData, getChatsByToProperty, openDB } from "../../db/indexedDB";
import chatterStore from "../../store/chatterStore";
import useSignUpStore from "../../services /store/useSignupStore";
import { fetchChatOfChatterOrGroup } from "../../zustand/cloud/cloud.action";
import Loader from "../loader/loader";
import { addChatterToLocalStorage, doesNumberExistInLocalStorage } from "../../utils/helper";
import { includes } from "lodash";
import { useNavigate } from "react-router-dom";

const WhatsApp = ({db, setDb}) => {
  const { getProfilepictureStore, fetchChatters, fetchContacts, error } = chatterStore();

  const [chatsLoading, setChatLoading] = useState(false);
  const [groupIndividualProfile, setGroupIndividualProfile] = useState(false);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [progress, setProgress] = useState(5);
  const [darkMode, setDarkMode] = useState(() => {
    // Check localStorage for saved theme, default to false if none
    const savedDarkMode = localStorage.getItem('darkMode');
    return savedDarkMode ? JSON.parse(savedDarkMode) : false;
  });
  const [currentLanguage, setCurrentLanguage] = useState("en");


  const toggleDarkMode = useCallback(() => {
    setDarkMode((prevDarkMode) => {
      const newDarkMode = !prevDarkMode;

      // Add or remove the "dark" class on the <html> element
      if (newDarkMode) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }

      // Save preference to localStorage
      localStorage.setItem('darkMode', JSON.stringify(newDarkMode));

      return newDarkMode;
    });
  }, []);

  useEffect(() => {
    // Apply the saved dark mode setting on initial load
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);


  const [number, setNumber] = useState(localStorage.getItem("whatsappNumber"));

  const [chatter, setChatter] = useState([]);
  const [visibleChats, setVisibleChats] = useState([]);

  const {userData, checkUserExistence} = useSignUpStore();



  useEffect(() => {
    if(!userData) return;
    setNumber(userData.mobile);
}, [userData]);

  useEffect(() => {
    checkUserExistence(localStorage.getItem("userEmail"));
    fetchContacts();
  }, []);

  const navigate = useNavigate();
  const handleLogout = () => {
    try {
      localStorage.removeItem("loginEmail");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("currentOrgCode");
      localStorage.removeItem("currentOrgId");
      localStorage.removeItem("userMobile");
      localStorage.removeItem("currentOrgName");
      localStorage.removeItem("workspaceId");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("eazybe-storage");
      localStorage.removeItem("userRoleId");
      localStorage.removeItem("whatsappNumber");
      localStorage.removeItem("userInitialData");
      localStorage.removeItem("darkMode");
      localStorage.removeItem("fetchedChatters")
      localStorage.removeItem("persist")

      setSelectedChat("");
      setCurrentLanguage("");
      setVisibleChats("");
      clearIndexedDB();

      // console.log("Logout");
      navigate("/signup");
    } catch (err) {
      console.log("Error while logging out", err);
    }
  };

  useEffect(() => {
    console.log('error', error);
    if(error){
      handleLogout();
    }
  }, [error]);


  const [pusherData, setPusherData] = useState(null);
  
  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher("9df49e8dfba9b1f1cdb5", {
      cluster: "ap2",
    });

    // Subscribe to the channel
    // console.log('pusher number', number);
    
    const channel = pusher.subscribe(`${number}`);

    console.log(channel)

    // Bind to the event
    channel.bind('message.any', function (data) {
      // Update state with the received data
      // console.log('pusher data', data);
      
      setPusherData(data);
      // alert(JSON.stringify(data)); // You can remove this alert in production
    });

    // Bind to the event
    channel.bind('message.reaction', function (data) {
      // Update state with the received data
      console.log('pusher data', data);
      
      setPusherData(data);
      // alert(JSON.stringify(data)); // You can remove this alert in production
    });

    // Cleanup on unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [number]);

  // console.log('pusher-Data', pusherData);
  
  useEffect(() => {
    (async () => {
      // console.log('starting db making 1');
      
      const initializeDB = async () => {
        const dbName = 'whatsappDB';
        const dbInstance = await openDB(dbName, 2);
        setDb(dbInstance);
        // console.log('starting db making 2');
        // Load existing chatter and chats
        const savedChatter = await getAllData(dbInstance, 'chatter');
        // console.log('savedChatter', savedChatter);
        const sortedChatData = savedChatter.sort((a, b) => b?.conversationTimestamp - a?.conversationTimestamp);
        // setChatter(sortedChatData);
      };
  
      await initializeDB(); // Await the completion of initializeDB
    })();
  }, [number]);
  

  const handleAddChatter = async () => {
      if (db && number) { // Check if both db and number are available
        // console.log('db testing', db,number);
        try {
          let Chatterdata = await fetchChatters(number);
          // console.log('Chatterdata - DB', Chatterdata);

          if(!Chatterdata) return;
          
          const chatterArray = Chatterdata || [];
          // console.log('chatterArray', chatterArray);
          
          if (chatterArray?.length) {
            await Promise.all(
              chatterArray.map((chatter) => {
                const chatterWithId = {
                  ...chatter,
                  id: chatter?.id,
                };
                return addData(db, 'chatter', chatterWithId);
              })
            );
            
            // console.log('sortedChatData before', chatterArray);
            const sortedChatData = chatterArray.sort((a, b) => b?.conversationTimestamp - a?.conversationTimestamp);
            // console.log('sortedChatData after', sortedChatData);
            
            setChatter(sortedChatData);
          }
        } catch (error) {
          console.error("Error storing chatter in indexedDB:", error);
        }
      } else {
        console.log('db or number is not available');
      }
  };
  
  const [chatsCount, setChatsCount] = useState(20);
  const handleAddChats = async (number, selectedChat, chatterPerson) => {
    // console.log("handleAddChats", { number, selectedChat, chatter });

    if (db) {
      // console.log("db testing chat", db);
      try {
        // Use Promise.all to handle multiple asynchronous operations
        // console.log("user data", { number, selectedChat });
        if(selectedChat?.includes("@s.whatsapp.net")){
          selectedChat = selectedChat?.replace("@s.whatsapp.net", "@c.us");
        }
        else{
          selectedChat = selectedChat
        }
        const localChats = await getChatsByToProperty(db, "chats", selectedChat);
        // console.log("Local chats fetched:", localChats);
        localChats.sort((a, b) => (b?.data?.timestamp || 0) - (a?.data?.timestamp || 0));

        // console.log("Local chats fetched and sorted:", localChats);
        // setChats(localChats);
    
        let lastTimeSync = localChats?.[0]?.data?.timestamp;
        // console.log("Last time sync:", lastTimeSync);

        if(!doesNumberExistInLocalStorage(selectedChat)){
          let Chatterdata = await fetchChatOfChatterOrGroup(number, selectedChat, lastTimeSync);
          // console.log("Chatsdata", Chatterdata);
          
          let lastMessage = Chatterdata?.[0];
          // console.log("lastMessage", lastMessage);

          chatterPerson.lastMessage = lastMessage;
          let chatterWithId = {
            id: selectedChat,
            data: chatterPerson
          }
          addData(db, 'chatter', chatterWithId);

          if(Chatterdata){
            addChatterToLocalStorage(selectedChat);
          }
          // console.log("Chatsdata - DB", Chatterdata);

          const chatsArray = Chatterdata || [];
          // console.log("chatsArray before", chatsArray);

          await Promise.all(
            chatsArray?.map((chats, index) => {
              // console.log('chatsArray', chats);

              // Ensure each chatter object has a unique id
              const chatterWithId = {
                // id: `${selectedChat}_${chats.id}`,
                id: chats.id,
                data: chats,
              };

              // Add each chatter to the indexedDB
              addData(db, "chats", chatterWithId);
            })
          );
        }
      } catch (error) {
        console.error("Error storing chats in indexedDB:", error);
      }
    }
  };

  const [flag, setFlag] = useState(false);
  useEffect(() => {
    // console.log("useEffect chatter", chatsCount, lastFetchCount.current);
    const initialChatsCall = async() => {
      const initialchats = chatter
        ?.sort((a, b) => b.conversationTimestamp - a.conversationTimestamp)
        .slice(0, chatsCount);
      if (flag == false) {
        await firstchatsFetch(initialchats);
        // console.log("setInitialLoading");
        setInitialLoading(false);
      }
      
      setVisibleChats(initialchats); 
    }

    initialChatsCall();
  }, [chatter, chatsCount]);

  async function firstchatsFetch(initialchats) {
    // console.log("firstchatsFetch", initialchats);
    for (const chat of initialchats) {
      const serializedId = chat?.id;
      if(serializedId === "status@broadcast") continue;
      if (serializedId) {
        // Wait for the API call to finish before proceeding to the next
        await getProfilepictureStore(number, number, serializedId)
        await handleAddChats(number, serializedId, chat);
        
        setProgress((prev) => {
          if (prev < 100) {
            return prev + 5;
          }
          return prev; // Keep progress unchanged if it's already 100
        });  
      }
    }
    if (initialchats.length) {
      setFlag(true);
    }
  }

  useEffect(() => {
    // console.log("checking initial render", number, db);
    if (number && db) {
      // console.log("setInitialLoading", number, db);
      setInitialLoading(true);
      handleAddChatter();
    }
  }, [number, db]);
  

  let email = localStorage.getItem("userEmail");
  


  useEffect(() => {
    localStorage.removeItem("fetchedChatters");
  }, [])

  // console.log("Number", number);
  return (
    <>
      {
        initialLoading ? 
        (
          <Loader progress={progress} />
        ) 
        : 
        (
          <>
            <div className="h-screen">
              <div className="h-[20vh] w-full bg-[#00A884] dark:bg-[#262524]"></div>
              <div className="h-[80vh] w-full bg-[#DCE1DE] dark:bg-[#262524]"></div>
            </div>

            <div className="flex h-[94vh] bg-gray-100 dark:bg-gray-900 w-[98vw] absolute top-5 left-[0.9rem] shadow-lg">
              <ChatterSidebar
                number={number}
                darkMode={darkMode}
                chatter={chatter}
                toggleDarkMode={toggleDarkMode}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
                currentLanguage={currentLanguage}
                setCurrentLanguage={setCurrentLanguage}
                setChatter={setChatter}
                pusherData={pusherData}
                visibleChats={visibleChats}
                setVisibleChats={setVisibleChats}
                setGroupIndividualProfile={setGroupIndividualProfile}
                groupIndividualProfile={groupIndividualProfile}
                setChats={setChats}
                chats={chats}
                handleAddChats={handleAddChats}
                handleLogout={handleLogout}
              />
              <MainChatArea
                number={number}
                setNumber={setNumber}
                chatter={chatter}
                selectedChat={selectedChat}
                pusherData={pusherData}
                setPusherData={setPusherData}
                setChatter={setChatter}
                chatsLoading={chatsLoading}
                setChatLoading={setChatLoading}
                setSelectedChat={setSelectedChat}
                darkMode={darkMode}
                setGroupIndividualProfile={setGroupIndividualProfile}
                groupIndividualProfile={groupIndividualProfile}
                setChats={setChats}
                chats={chats}
              />
            </div>
          </>
        )
      }
    </>
  );
};

export default WhatsApp;
