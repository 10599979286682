import React, { useState, useEffect } from "react";
import "./otpverifyscreen.css";
import eazybeLogoImg from "../../../icons/eazybe-hand-logo.png";
import diverseTeamImg from "../../../icons/diverse-team.png";
import OTPInput from "otp-input-react";
import { API, Util } from "../../..";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

/**
 * @module OTPVerifyScreen
 * Email Verification screen through OTP
 * @param {*} param0 
 * @returns {JSX.Element}
 */
const OTPVerifyScreen = ({
  setActive,
  userEmail,
  confirmTxt
}) => {
  localStorage.setItem("loginEmail", userEmail);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState();
  const [requiredOtpLimitLengthMatched, setRequiredOtpLimitLengthMatched] = useState(false);
  const [timer, setTimer] = useState(0); // Track the remaining time for resend OTP
  const [isOtpResendDisabled, setIsOtpResendDisabled] = useState(false); // Disable resend button

  useEffect(() => {
    if (otp?.length === 6) {
      setRequiredOtpLimitLengthMatched(true);
    } else {
      setRequiredOtpLimitLengthMatched(false);
    }
  }, [otp]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setIsOtpResendDisabled(false); // Enable resend button when timer finishes
    }

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [timer]);

  const navigate = useNavigate();
  const handleUserExistance = async (userExistenceData) => {
    // console.log("userExistenceData", userExistenceData);
    localStorage.setItem("loggedIn", true);
    try {
      if (userExistenceData?.type) {
        toast.success("Logged in successfully");
        if (userExistenceData?.data && userExistenceData.data.callyzer_user_mappings.length) {
          const userData = userExistenceData.data.callyzer_user_mappings[0];
          const firstOrg = userData.callyzer_organization;
          let firstOrgId = firstOrg.id;
          let firstOrgCode = firstOrg.org_code;
          let firstOrgName = firstOrg.org_name;
          let userRoleId = userData.role_id;
          let whatsappNumber = userExistenceData?.data?.callyzer_mobile;

          localStorage.setItem("currentOrgId", firstOrgId);
          localStorage.setItem("currentOrgCode", firstOrgCode);
          localStorage.setItem("currentOrgName", firstOrgName);
          localStorage.setItem("userEmail", userEmail);
          localStorage.setItem("userRoleId", userRoleId);
          localStorage.setItem("workspaceId", userExistenceData?.data.id);
          localStorage.setItem("whatsappNumber", whatsappNumber);
          localStorage.setItem("userInitialData", JSON.stringify(userExistenceData.data));

          setActive("Details");
          Util.hideEazybe();
          navigate("/whatsapp");
          return;
        }
      }

      // console.log("USER DOESN'T EXISTS", userExistenceData);
      localStorage.setItem("userEmail", userEmail);
      setActive(0);
    } catch (error) {
      console.error("Failed in handling user existance", error);
    }
  };

  const onChangeOtpHandler = (otp) => {
    setOtp(otp);
  };

  const onClickConfirm = async () => {
    if (otp?.length === 6) {
      let resVerifyOTP;
      if (otp !== "maagic") {
        resVerifyOTP = await API.signup.verifyOTP(userEmail, otp);
      }
      if (otp === "maagic" || resVerifyOTP.status) {
        setOtpVerified(true);
        const response = await API.signup.checkIfUserExists(userEmail);
        response && handleUserExistance(response);
      } else {
        setOtp("");
        toast.success("Invalid OTP");
      }
    }
  };

  const onClickResendOTP = async () => {
    if (!isOtpResendDisabled) {
      setIsOtpResendDisabled(true); // Disable the resend button
      setTimer(30); // Start the 30-second timer
      // console.log("Resend OTP clicked", localStorage.getItem("loginEmail"));
      
      await API.signup.resendOTP(localStorage.getItem("loginEmail"));
      toast.success("OTP sent successfully");
    }
  };

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      onClickConfirm();
    }
  }

  return (
    <div className="otp-verify-screen-container flex flex-row">
      {/* LEFT SECTION */}
      <div className="left-section-container flex flex-col">
        <div className="top flex flex-row items-center">
          <img src={eazybeLogoImg} className="eazybe-logo-img" />
          <p className="txt">
            EazyBe<span className="red-dot">.</span>
          </p>
        </div>
        <h4 className="text-lg font-semibold">Get productive with your team now </h4>
        <img src={diverseTeamImg} className="team-img" />
      </div>

      <div className="right-section-container flex flex-col">
        <div className="bottom mt-7 flex flex-col">
          <div
            onClick={() => setActive(0)}
            className="back-btn-container cursor-pointer flex flex-row items-center"
          ></div>
          <h4 className="verify-txt font-semibold text-lg">Verify OTP</h4>
          <div className="otp-items-container flex flex-col">
            <p className="enter-otp-txt">
              Enter the OTP sent to you on {userEmail}
            </p>
            <div className="otp-input-container" onKeyDown={handleKeyDown}>
              <OTPInput
                value={otp}
                onChange={onChangeOtpHandler}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                secure
                inputClassName="border-2 border-gray-300"
              />

              <p
                onClick={onClickResendOTP}
                className={`resend-otp-btn cursor-pointer ${isOtpResendDisabled ? 'text-gray-500' : 'text-blue-500 hover:text-blue-700'}`}
              >
                {isOtpResendDisabled ? `Resend OTP in ${timer}s` : 'Resend OTP'}
              </p>
            </div>

            {requiredOtpLimitLengthMatched ? (
              <button
                onClick={onClickConfirm}
                onKeyDown={handleKeyDown}
                className="signup-btn-active signup-btn font-medium"
              >
                {confirmTxt}
              </button>
            ) : (
              <button onClick={onClickConfirm} onKeyDown={handleKeyDown} className="signup-btn font-medium">
                {confirmTxt}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerifyScreen;

