import { API, Util } from "..";

/**
 * @function getContactNameByChatId
 * @description To get contact name by phone number from model
 * @param {*} string chat id of the chatter
 * @returns {object | ""} 
 */
const getContactNameByChatId = (chatId) => {
    try {
      const chat = Util.getChatId(chatId);

      if (chat) {
        return chat.push_name || "";
      } else {
        return "";
      }
    } catch (err) {
      console.error("Error while getting contact name by chat ID: ", err);
      return "";
    }
};

/**
   * Handler for uploading new messages
   * @function handleUploadMessage
   * @param {object} detail
   * @returns {Promise<boolean>}
   */
export const handleUploadMessage = async (detail) => {
    // console.log("listenerForNewMessage event", event);
    const userMob =  Util.getLoggedInUserMobile();
    const workspaceId = Util.getWorkspaceId();

    if(!workspaceId) {
      return false;
    }

    // const clientWhatsappNameByGroup =  detail?.ChannelId?.includes("g.us") ? await getContactNameByChatId(detail?.ChannelId)  : await getContactNameByChatId(detail?.SentById);
    // const sentByName = await getContactNameByChatId(detail?.sentByUserId);
    // let currentMessage = { ...detail, CreatedByUser: userMob, ChannelName :   clientWhatsappNameByGroup, senderName : sentByName   };

    // console.log("webhook getNewMessageDataListener webhookData", webhookData);
    // console.log("webhook getNewMessageDataListener event", event);
    // console.log("webhook getNewMessageDataListener detail", detail);
    // console.log("webhook getNewMessageDataListener currentMessage", currentMessage);

    const webhookData = JSON.parse(localStorage.getItem("webhook_data"));

    if (detail && webhookData) {
      const outgoingEndpoint = webhookData.duplex_webhook ?? webhookData.outgoing;
      const authToken = webhookData.auth_token;

      const payload = {
        endPoint: outgoingEndpoint,
        msgObject: detail,
        authToken : authToken
      }

      const uploadStatus = await API.webhook.sendEazybeWebhookMessageData(workspaceId, {
        data : payload
        })

      return uploadStatus;
    }

    return false;
  };

/**
 * @function updateMessagesFromLastSyncTime
 * @description when user open/refresh whatsapp we will fetch their last sync time for webhook
 * then we will get all the messages after the last sync time till current time and upload them using webhook.
 * to get the webhook last sync time we will call api {@link API.webhook.getWebhookLastSyncTime}.
 * @async
 * @returns {void}
 */
export const updateMessagesFromWebhookLastSyncTime = async () => {
  const workspaceId = Util.getWorkspaceId();

  if(!workspaceId) {
    return;
  }

  const lastSyncTime = await API.webhook.getWebhookLastSyncTime(workspaceId);
  
  if(!lastSyncTime?.result) {
    return;
  }

  if(!lastSyncTime.data.hasOwnProperty("webhook") || typeof lastSyncTime.data.webhook !== "string") {
    return;
  }

  const lastTime = new Date(lastSyncTime.data.webhook).getTime();
  const chatterConversationAfterLastTime = await Util.getChatIdArrayFromModelAfterTime(lastTime);
  const {syncObject} = await Util.createChatterMessagesObject(
    chatterConversationAfterLastTime,
     Util.getLoggedInUserMobile(),
     lastTime
    );

    const webhookData = JSON.parse(localStorage.getItem("webhook_data"));

    if (!chatterConversationAfterLastTime.length || !webhookData) {
      return;
    }
    
      const outgoingEndpoint = webhookData.outgoing;
      const authToken = webhookData.auth_token;

      const payload = {
        endPoint: outgoingEndpoint,
        msgObject: syncObject,
        authToken : authToken
      }

      await API.webhook.sendEazybeWebhookMessageData(workspaceId, {
        data : payload
        })
}