import React, { useEffect, useRef, useState } from "react";
import emoji from "../../../icons/emoji.png";
import { sendAudioVoiceRecords, sendfiles, sendImages, sendMessage, sendVideo } from "../../../zustand/cloud/cloud.action";
import { openDB } from "../../../db/indexedDB";
import { convertMessageObject } from "../../../utils/helper";
import Picker from "emoji-picker-react";
import { FileText, Image, Camera, User, BarChart2, Sticker, X, Captions } from "lucide-react"
import { Button } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { toast } from "react-toastify";

const SentMessage = ({ number, selectedChat, chats, setChats, setSend }) => {
  // console.log('SentMessage', number, selectedChat, chats, setChats, setSend );

  const darkMode = localStorage.getItem("darkMode");
  // console.log('darkMode', darkMode);
  

  const [message, setMessage] = useState("");
  const [response, setResponse] = useState(null);
  const [sending, setSending] = useState(false); // New state to track sending status
  const [db, setDb] = useState(null);

  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if(!message.length || sending) {
      return;
    }

    setSending(true); // Set sending to true to disable the button

    let body = {
      chatId: selectedChat,
      reply_to: null,
      text: message,
      session: number,
    };

    try {
      const responseMsg = await sendMessage(body, number);
      // console.log("send message response", { responseMsg, chats });
      setResponse(convertMessageObject(responseMsg.data));
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setSending(false); // Reset sending status after the message is sent
    }

    setMessage(""); // Clear the input field
  };

  const handleSendMedia = async (e) => {
    e.preventDefault();
    setSending(true);
  
    if (!file) return;

    try {
      setSend(true);
      
      // File validation
      const maxSize = 15 * 1024 * 1024; // 15MB
      // console.log('file size', file.size, maxSize);
      
      if (file.size > maxSize) {
        // console.log('file size');
        throw new Error('File is too large. Maximum size is 15MB.');
      }

      if(file.type.includes("audio")) {
        const base64String = await convertToBase64(file);
        let fileToSend = {
          filename: file.name,
          mimetype: file.type,
          data: base64String.split("base64,")[1]
        };

        let payload = {
          chatId: selectedChat, // Chat ID
          file: fileToSend, // The selected file (image, video, or document)
          session: number
        };

        let response = await sendAudioVoiceRecords(payload, number);

        if (response.status) {
          // console.log("Message sent successfully!");
          // Reset file and message input after successful send
          setFile(null);
          setMessage("");
        } else {
          setFile(null);
          setMessage("");
          toast.success("Error while sending Audio")
          console.error("Error sending message:", response.statusText);
        }
      }
      else if(file.type.includes("image")) {
        const base64String = await convertToBase64(file);
        let fileToSend = {
          filename: file.name,
          mimetype: file.type,
          data: base64String.split("base64,")[1]
        };

        let payload = {
          chatId: selectedChat, // Chat ID
          file: fileToSend, // The selected file (image, video, or document)
          session: number,
          caption: ""
        };

        let response = await sendImages(payload, number);

        if (response.status) {
          // console.log("Message sent successfully!");
          // Reset file and message input after successful send
          setFile(null);
          setMessage("");
        } else {
          setFile(null);
          setMessage("");
          toast.success("Error while sending Audio")
          console.error("Error sending message:", response.statusText);
        }
      }
      else if (file.type.startsWith("video")) {
        const base64String = await convertToBase64(file);
    
        let fileToSend = {
            filename: "video.mp4", // Use actual filename
            mimetype: "video/mp4", // Use actual MIME type
            data: base64String.split("base64,")[1],
        };
    
        let payload = {
            chatId: selectedChat, // Chat ID
            file: fileToSend, // The selected file (image, video, or document)
            session: number,
            caption: message || "", // Optional caption
            asNote: false,
        };
    
        // API Request
        try {
            let response = await sendVideo(payload, number);
    
            if (response && response.status) {
                setFile(null);
                setMessage("");
                toast.success("Video sent successfully!");
            } else {
                setFile(null);
                setMessage("");
                toast.error("Error while sending video");
                console.error("Error sending video:", response?.statusText || "Unknown error");
            }
        } catch (error) {
            console.error("Error in sendVideo API:", error);
            toast.error("An error occurred while sending the video.");
        }
      }
      else {
        const base64String = await convertToBase64(file);
        let fileToSend = {
          filename: file.name,
          mimetype: file.type,
          data: base64String.split("base64,")[1]
        };

        let payload = {
          chatId: selectedChat, // Chat ID
          file: fileToSend, // The selected file (image, video, or document)
          session: number,
          caption: ""
        };

        let response = await sendfiles(payload, number);

        if (response.status) {
          // console.log("Message sent successfully!");
          // Reset file and message input after successful send
          setFile(null);
          setMessage("");
        } else {
          setFile(null);
          setMessage("");
          toast.success("Error while sending Audio")
          console.error("Error sending message:", response.statusText);
        }
      }
      setFile(null);
      e.target.value = '';
    } catch (error) {
      console.error('Upload error:', error);
      alert(error.message);
    } finally {
      setSending(false);
    }
  
  };
  
  // Convert file to base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.onload = () => {
        // Remove the data:image/jpeg;base64, prefix if needed
        // const base64String = reader.result.split(',')[1];
        resolve(reader.result);
      };
      
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  
  const handleMessages  = (e)=> {
    if(file){
      handleSendMedia(e);
    }
    else{
      handleSendMessage(e);
    }
  }

  useEffect(() => {
    const initializeDB = async () => {
      const dbName = "whatsappDB";
      const dbInstance = await openDB(dbName, 2);
      setDb(dbInstance);
    };
    initializeDB();
  }, [selectedChat]);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiButtonRef = useRef(null);
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target) &&
        !emojiButtonRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onEmojiClick = (event, emojiObject) => {
    let a = event.emoji;
    // console.log("onEmojiClick", { a });
    setMessage((prevMessage) => prevMessage + event.emoji);
  };

  const [pdfUrl, setPdfUrl] = useState(null);

  const handleFileUpload = (event)=> {
    const selectedFile = event.target.files?.[0]
    if (selectedFile) {

      if (selectedFile && selectedFile.type === "application/pdf") {
        const pdfUrl = URL.createObjectURL(selectedFile); // Create a Blob URL for the PDF
        setPdfUrl(pdfUrl);
      }

      setShowEmojiPicker(false);
      setSendFilesDropDown(false);
    
      const reader = new FileReader();
    
      reader.onload = (e) => {
        // Set the image and file states
        setImage(e.target?.result); // Set the base64 image data
        setFile(selectedFile); // Set the selected file
      };
    
      reader.readAsDataURL(selectedFile);
    }
    
    // console.log('handleFileUpload', selectedFile); 
  }

  const handleKeyDown = (e) => {
    // If Enter is pressed without Shift, submit the form
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleMessages(e);
    }
  };

  const handleInput = (e) => {
    setMessage(e.target.value);

    // Adjust the height of the textarea to fit content
    e.target.style.height = 'auto'; // Reset the height to auto to shrink if needed
    e.target.style.height = `${Math.min(e.target.scrollHeight, 200)}px`; // Max height set to 100px
  };

  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const streamRef = useRef(null);
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
  
      // Store the stream to stop it later
      streamRef.current = stream;
  
      // Clear previous chunks
      audioChunksRef.current = [];
  
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
  
      mediaRecorderRef.current.onstop = handleRecordingStop;
  
      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error("Microphone access denied or unavailable:", error);
    }
  };
  
  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setRecording(false);
  
    // Stop all audio tracks to release the microphone
    streamRef.current?.getTracks().forEach((track) => track.stop());
  };
  
  // const handleRecordingStop = () => {
  //   const audioBlob = new Blob(audioChunksRef.current, { type: "audio/ogg; codecs=opus" });
  //   const audioFile = new File([audioBlob], `recording_${Date.now()}.ogg`, {
  //     type: "audio/ogg; codecs=opus",
  //   });
  
  //   // Set the file in state
  //   setFile(audioFile);
  
  //   // Reset chunks
  //   audioChunksRef.current = [];
  // };

  const handleRecordingStop = () => {
    // Create an audio Blob with OPUS encoding in an OGG container
    const audioBlob = new Blob(audioChunksRef.current, { type: "audio/ogg; codecs=opus" });
    
    // Create a File object from the Blob
    const audioFile = new File([audioBlob], `recording_${Date.now()}.ogg`, {
      type: "audio/ogg; codecs=opus",
    });
  
    // Save the file in the state
    setFile(audioFile);
  
    // Clear the chunks array for the next recording
    audioChunksRef.current = [];
  };
  
  
  
  const [sendFileDropDown ,setSendFilesDropDown] = useState(false);

  return (
    <form
      onSubmit={handleMessages}
      className="relative flex items-center dark:bg-[#1F2C33] bg-[#EFF2F5] p-4 border-t border-gray-300"
    >
      {/* <img
        ref={emojiButtonRef}
        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        src={emoji}
        height={"25px"}
        width={"25px"}
      /> */}
      <span 
        ref={emojiButtonRef}
        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        className="h-[25px] w-[25px] flex items-center justify-center cursor-pointer"
      >
        <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="x23j0i4 xd7y6wv" version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24"><title>smiley</title><path fill="#54646F" d="M9.153,11.603c0.795,0,1.439-0.879,1.439-1.962S9.948,7.679,9.153,7.679 S7.714,8.558,7.714,9.641S8.358,11.603,9.153,11.603z M5.949,12.965c-0.026-0.307-0.131,5.218,6.063,5.551 c6.066-0.25,6.066-5.551,6.066-5.551C12,14.381,5.949,12.965,5.949,12.965z M17.312,14.073c0,0-0.669,1.959-5.051,1.959 c-3.505,0-5.388-1.164-5.607-1.959C6.654,14.073,12.566,15.128,17.312,14.073z M11.804,1.011c-6.195,0-10.826,5.022-10.826,11.217 s4.826,10.761,11.021,10.761S23.02,18.423,23.02,12.228C23.021,6.033,17.999,1.011,11.804,1.011z M12,21.354 c-5.273,0-9.381-3.886-9.381-9.159s3.942-9.548,9.215-9.548s9.548,4.275,9.548,9.548C21.381,17.467,17.273,21.354,12,21.354z  M15.108,11.603c0.795,0,1.439-0.879,1.439-1.962s-0.644-1.962-1.439-1.962s-1.439,0.879-1.439,1.962S14.313,11.603,15.108,11.603z"></path></svg>
      </span>
      {showEmojiPicker && (
        <div
          ref={pickerRef}
          className="absolute bottom-12 left-0 z-50 w-80 bg-white border border-gray-300 shadow-lg rounded-lg"
        >
          <Picker width="560px" theme={`${darkMode}` ? 'dark' : 'light'} onEmojiClick={onEmojiClick} />
        </div>
      )}
      <span
        style={sendFileDropDown ? { backgroundColor: '#D8DBDF' } : null}
        onClick={() => setSendFilesDropDown(!sendFileDropDown)}
        aria-hidden="true"
        data-icon="plus"
        className="p-[12px] rounded-full flex text-center ml-2 mr-4"
      >
        <svg viewBox="0 0 24 24" width="30" preserveAspectRatio="xMidYMid meet">
          <title>plus</title>
          <path fill="#63727D" d="M19,13h-6v6h-2v-6H5v-2h6V5h2v6h6V13z"></path>
        </svg>
      </span>

      {sendFileDropDown && (
        <div className="w-[12rem] bg-white rounded-xl shadow-lg pt-4 pb-4 absolute bottom-[3.5rem] left-[3.5rem]">
          <div className="space-y-2">
            <label className="w-full flex items-center px-3 py-1 text-left text-sm font-normal text-gray-700 hover:bg-gray-100 rounded-lg transition-colors cursor-pointer">
              <FileText className="mr-2 h-5 w-5 text-indigo-500" />
              Document
              <input
                type="file"
                accept="*"
                className="hidden"
                onChange={(e)=> handleFileUpload(e)} // Handle file input
              />
            </label>
            <label className="w-full flex items-center px-3 py-1 text-left text-sm font-normal text-gray-700 hover:bg-gray-100 rounded-lg transition-colors cursor-pointer">
              <Image className="mr-2 h-5 w-5 text-blue-500" />
              Photos & videos
              <input
                type="file"
                accept="image/*,video/*"
                className="hidden"
                onChange={(e)=> handleFileUpload(e)} // Handle photo/video input
              />
            </label>
          </div>
        </div>
      )}

      {/* <input
        type="text"
        placeholder={file ? "Add a caption" : "Type a message"}
        className="flex-1 text-sm p-3 rounded-lg mr-4 bg-white dark:bg-[#2A3942] text-gray-800 dark:text-gray-200 focus:outline-none"
        value={message}
        onChange={(e)=> {setMessage(e.target.value); setSendFilesDropDown(false);}}
      /> */}
      <textarea
        placeholder={file ? "Add a caption" : "Type a message"}
        className="flex-1 text-sm p-3 rounded-lg mr-4 bg-white dark:bg-[#2A3942] text-gray-800 dark:text-gray-200 focus:outline-none resize-none overflow-y-auto"
        value={message}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        rows={1}
        style={{ maxHeight: '100px' }} // Limit the height for scrolling effect
      />
      {/* <button
        type="submit"
        className={`text-white rounded-full ${
          sending ? "opacity-50 cursor-not-allowed" : "",
          file ? "bg-[#04A784] p-4" : "p-2"
        }`}
        disabled={sending} // Disable the button while sending
      >
        {message.length > 0 || file ? (
          <span aria-hidden="true" data-icon="send">
            <svg viewBox="0 0 24 24" height="24" width="24">
              <title>send</title>
              <path fill={file ? "white" : "#63727D" } d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z"></path>
            </svg>
          </span>
        ) : (
          <span aria-hidden="true" data-icon="ptt">
            <svg viewBox="0 0 24 24" height="24" width="24">
              <title>ptt</title>
              <path
                fill="#63727D"
                d="M11.999,14.942c2.001,0,3.531-1.53,3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531 S8.469,2.35,8.469,4.35v7.061C8.469,13.412,9.999,14.942,11.999,14.942z M18.237,11.412c0,3.531-2.942,6.002-6.237,6.002 s-6.237-2.471-6.237-6.002H3.761c0,4.001,3.178,7.297,7.061,7.885v3.884h2.354v-3.884c3.884-0.588,7.061-3.884,7.061-7.885 L18.237,11.412z"
              ></path>
            </svg>
          </span>
        )}
      </button> */}
      <button
        type={message.length > 0 || file ? "submit" : "button"} // Use "submit" if message/file is present
        onClick={
          recording
            ? stopRecording // If recording, stop it
            : message.length === 0 && !file // If no message/file, start recording
            ? startRecording
            : null // Otherwise, let form submission handle it
        }
        className={`text-white rounded-full ${
          sending ? "opacity-50 cursor-not-allowed" : "",
          file ? "bg-[#04A784] p-4" : "p-2"
        }`}
        disabled={sending}
      >
        {message.length > 0 || file ? ( // Show "send" icon if there’s a message or file
          <span aria-hidden="true" data-icon="send">
            <svg viewBox="0 0 24 24" height="24" width="24">
              <title>Send</title>
              <path
                fill={file ? "white" : "#63727D"}
                d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z"
              ></path>
            </svg>
          </span>
        ) : recording ? ( // Show "stop" icon while recording
          <span aria-hidden="true" data-icon="stop">
            <svg viewBox="0 0 24 24" height="24" width="24">
              <title>Stop</title>
              <rect x="4" y="4" width="16" height="16" fill="#63727D"></rect>
            </svg>
          </span>
        ) : ( // Show "microphone" icon by default
          <span aria-hidden="true" data-icon="ptt">
            <svg viewBox="0 0 24 24" height="24" width="24">
              <title>Microphone</title>
              <path
                fill="#63727D"
                d="M11.999,14.942c2.001,0,3.531-1.53,3.531-3.531V4.35c0-2.001-1.53-3.531-3.531-3.531 S8.469,2.35,8.469,4.35v7.061C8.469,13.412,9.999,14.942,11.999,14.942z M18.237,11.412c0,3.531-2.942,6.002-6.237,6.002 s-6.237-2.471-6.237-6.002H3.761c0,4.001,3.178,7.297,7.061,7.885v3.884h2.354v-3.884c3.884-0.588,7.061-3.884,7.061-7.885 L18.237,11.412z"
              ></path>
            </svg>
          </span>
        )}
      </button>

      {file && (
        // <div className="absolute p-[2rem] bg-[#E9EDEF] flex flex-col items-center justify-center bottom-[8rem] right-[2rem]">
        <div className="absolute h-[39rem] w-[60rem] bg-[#E9EDEF] flex flex-col items-center justify-center bottom-[5rem] right-[0rem]">
          <div className="w-1/2 absolute top-0 left-0 flex justify-between">
            <Button
              variant="destructive"
              size="icon"
              className="h-[5rem] w-[5rem] z-[100]"
              onClick={() => setFile(null)}
            >
              <X className="h-6 w-6" />
            </Button>
            <span className="mt-5">
              {file?.name}
            </span>
          </div>
          {file?.type === "application/pdf" && pdfUrl ? (
                <Worker className="h-1/2 w-1/2" workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
                  <Viewer className="h-1/2 w-1/2" fileUrl={pdfUrl} renderMode="canvas" />
                </Worker> 
              ) : image ? (
                  <img src={image} alt="Selected" className="w-[50rem] h-[30rem] object-contain rounded-lg" />
              ) : null}
        </div>
      )}
    </form>

  );
};

export default SentMessage;
